.container {
  display: grid;
  grid-template-columns: 20% 1fr;
  padding: 50px;
  grid-template-areas:
    "image userrating"
    "image text";
  margin-bottom: 20px !important;
}

.container .image-container {
  display: flex;
  /* border: 1px solid blue; */
  padding-right: 1rem;
  align-items: flex-start;
  justify-content: center;
  grid-area: image;
}

.container .image-container img {
  /* border: 1px solid green; */
  width: 80px;
}

.container .information-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-area: userrating;
}

.container .information-container .name-and-rating-container > h5 {
  margin: 0;
  font-weight: bold;
  padding-left: 5px;
}

.container .information-container .date-created-review { 
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}

.container .information-container .date-created-review > svg {
  margin-right: 5px;
  color: #ff1e45
}

.container .review {
  grid-area: text;
}

@media screen and (max-width: 600px) {
  .container {
    /* border: 1px solid red !important; */
    padding: 0;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-areas:
    "image userrating"
    "text text";
  }

  .container .image-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-area: image;
    margin: 0;
    padding: 5px 0 0 0;
  }

  .container .image-container img {
    height: 50px;
    width: 50px;
  }

  .container .information-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    grid-area: userrating;
  }
}