.counter-selector {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;

  &__label {
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }

  &__counter {
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    position: relative;
  }

  &__icon {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
  }
}