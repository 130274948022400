.page__title {
  margin-bottom: -30px;
}

.break__line {
  margin-top: 15px;
  font-weight: 600;
}

.cost_input {
  width: 70%;
  height: 50px;
  border: 1px solid black;
  margin-top: 20px;
}

.cost_input::placeholder {
  padding: 15px;
  font-size: 17px;
  font-weight: 500;
}

.cost_input[type='text'] {
  font-size: 17px;
  font-weight: 400;
}

.input__with__icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.input__with__icon .img__container {
  height: 52px;
  width: 52px;

  display: flex;
  align-items: center;
  justify-content: center;
}