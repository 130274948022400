.school__name {
  color: #36a7eb !important;
  font-weight: 500;
}

.limit-text{
  max-width: 500px;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.popup-title{
  color: #36a7eb;
  font-weight: 500;
}
