#page-map{
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 20%);
    border-color: rgba(0, 0, 0, 0.1);
}

.popup-buttons {
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
}

.popup-buttons button {
    width: 100%;
}

.button-contact {
    background-color: #36a7eb;
    color: white;
    font-weight: bolder;
    border-radius: 24px;
    border-color:none;
    border: none;
    outline: none;
    padding: 0.3em 1.0em;
    height: 30px;
    margin-left: 10px;
  }

.button-contact:hover{
    background-color: #0096ed;
}

.button-contact-disabled {
    background-color: rgb(229, 229, 229);
    color: white;
    font-weight: bolder;
    border-radius: 24px;
    border-color:none;
    border: none;
    outline: none;
    padding: 0.3em 1.0em;
    height: 30px;
    margin-left: 10px;
}

.school-public{
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.info-icon{
    align-items: center;
    display: flex;
    color: #0096ed;
    max-width: 110px;
}

.item{
    display: flex;
}

.icons-item{
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.info-icon:nth-child(1){
    margin-right: 4px;
}

.info-icon:nth-child(2){
    margin-right: 4px;
}

.map-popup{
    overflow: hidden;
}

.vacanted__mapView .leaflet-popup-content {
    margin: 0;
    height: auto !important;
    min-height: 210px;
}

.vacanted__mapView .leaflet-popup {
    border: none;
    box-shadow: rgb(0 0 0 / 10%) -4px 9px 25px -6px;
}

.vacanted__mapView .item p {
    margin-bottom: 0;
}

.vacanted__mapView .leaflet-popup-close-button {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    padding: 5px 10px 0 0 !important;
}

.map-popup .popup-address {
    font-size: 14px;
}