.react_modal_overlay {
    background: rgba(0, 0, 0, 0.5);
  
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react_modal_content {
    width: 100%;
    max-width: 600px;
    background: white;
    padding: 0 2rem 2rem 2rem;
    position: relative;
    border-radius: 0.24rem;
    overflow: auto;
  }

  .header_keep {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
  }

  .header_keep p {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 12px;
  }

  .header_keep h2 {
    font-size: 20px;
    margin-top: 1rem;
    color: black;
    font-weight: 600;
  }

  .header_close {
    display: flex;
    align-self: flex-end;
    margin-top: 0.5rem;
    position: absolute;
  }

  .modal_close {
    border: none;
    background: none;
  }
  
  .input {
    width: 100%;
    padding: 0.25rem 0.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
  
    border: 1px solid rgb(204, 204, 204);
    background: white;
    box-shadow: none;
    
    margin-bottom: 1rem;

    color: rgb(71, 71, 71);
    font-family: 'Montserrat', sans-serif;
    line-height: 1.618;
    font-size: 14px;
    font-weight: 400;
  }

  .select input {
    box-shadow: none;
  }

  .input:focus {
    outline: 2px solid rgb(38, 132, 255);
  }

  .keep_button {
    margin-top: 1.5rem;
    border: none;
    width: 100%;
    background-color: #36a7eb;
    padding: 4px 12px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    height: 40px;
  }

  .error {
    color: red;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    .react_modal_overlay {
      padding: 0;
      height: 100%;
      vertical-align: middle;
      align-items: center;
    }

    .react_modal_content {
      /* height: 90vh; */
      margin: 2rem 0.25rem;
      overflow: scroll;
    }

    .header_close {
      position: relative;
    }

    .header_keep h2 {
      margin-top: 0;
    }
  }
