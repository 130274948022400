.sectionContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.sectionContainer > h5 {
  color: #000;
  font-weight: bold;
  font-size: 1rem;
} 

.sectionContainer .containerOfActivities {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.sectionContainer > p {
  margin-top: 10px;
}


@media screen and (max-width: 900px) {
  .sectionContainer .containerOfActivities {
    display: grid;
    grid-template-columns: 1fr;
  }
}