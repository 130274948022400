@mixin button-base($button-height, $color) 
  display: inline-flex
  height: $button-height
  border: none
  vertical-align: middle

  &:hover
    cursor: pointer

  &:disabled
    color: $color
    cursor: default
    pointer-events: none
    

@mixin custom-shadow() 
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2)
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2)
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2)
  border-color: rgba(0,0,0,0.1)

