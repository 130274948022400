.upperLeftActions__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.upperLeftActions__container .ula__btn {
  padding: 1em;
  background-color: #fff;
  border-radius: 4px;
  max-height: 50px;
  min-width: 60px;
  width: auto;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 20%);
}

.upperLeftActions__container .ula__btn a {
  text-decoration: none;
  color: #0096ed;

  font-weight: 500;
}

.upperLeftActions__container .ula__btn .svg__container .noBtn {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.upperLeftActions__container .ula__btn .svg__container {
  margin: 0;
  padding: 0;
}

.upperLeftActions__container .ula__btn .svg__container svg {
  width: 22px;
  height: 22px;
  stroke-width: 1.5;
}

.upperLeftActions__container .ula__btn__disabled {
  padding: 1em;
  background-color: #e8ecf0;
  color: #fff;
  border-radius: 4px;
  max-height: 50px;
  min-width: 60px;
  width: auto;
  cursor: default;
  box-shadow: none;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.upperLeftActions__container .ula__btn__disabled p {
  margin: 0;
  padding: 0;

  font-weight: 500;
}
