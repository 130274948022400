.cardListContainer {
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  /* font-family: 'Inter', 'raleway', sans-serif; */
}

.activityListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #e8edef;
  padding: 20px 10px;
  margin-top: 20px;
}

.activitiesLyrics {
  /* border: 1px solid red; */
  padding: 10px;
}

@media all and (min-width: 1025px) {
  .cardBlock {
    width: 33%;
  }
}

@media only screen and (max-width: 1500px) {
  .activityListContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

  }
}

@media only screen and (max-width: 1000px) {
  .activityListContainer {
    display: grid;
    grid-template-columns: 1fr;

  }
}

@media only screen and (max-width: 600px) {
  .cardListContainer {
    justify-content: center;
  }

  .activityListContainer {
    display: grid;
    grid-template-columns: 1fr;

  }
}