.circularContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
}

.circularContainer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.circularContainer > div > img {
  width: 35px;
  background-color: #c5c5c5;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  padding: 5px;
}

.circularContainer > p {
  margin: 0;
  text-transform: capitalize;
}