.section {
  width: 100%;
  padding: 1em;
  padding-top: 10px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.sectionLine {
  margin: 0 5px 0 -1em;
  width: calc(100% + 2em);
}

section h3.sectionTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.6em;
  width: 100%;
  margin: 0.2em 0;
  padding: 0 5px;
}

.sectionContentWrapper {
  margin: 5px 5px 0;
  padding: 10px;
}

.subtitleSection {
  /* border: 1px solid red; */
  padding: 0px 10px;
  margin-top: 20px;
}

.subtitleSection > .title {
  font-size: 1rem;
  font-weight: bold;
}