.cardContainer {
  width: 250px;
  border: 1.5px solid #bebec1;
  margin: 10px;
  border-radius: 8px;

  padding: 20px 0;
}

.cardWrapper h3,
.cardWrapper p {
  margin: 0;

  text-align: center;
}

.cardWrapper .mainFeaturesImg {
  margin: auto;
}

.cardWrapper .mainFeaturesImg img {
  min-width: 35px;
  min-height: 35px;
}

.cardTitle {
  font-size: 1.3em;
  text-align: left;
  font-weight: bold;
  margin: 5% auto !important;
}

.cardText {
  font-size: 1.0em;
  font-weight: 400;

  color: #333;
}

.blue {
  color: #0096ed;

  cursor: pointer;

  font-weight: 500;
}

.refBtn {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none !important;
}

.cardWrapper {
  justify-content: center;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (min-width: 1025px) {
  .cardContainer {
    width: auto;
    height: auto;
  }
}