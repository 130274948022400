.sidebar-wrapper input, .sidebar-wrapper button, .sidebar-wrapper strong, .underbar p {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.sidebar-wrapper button {
  font-weight: 400;
}

.location-filter-dropdown {
  top: 0;
  width: 100%;
}
 
.sidebar-big-container {
  background-color: #ffffff;
  padding: 20px;
}

.sidebar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.responsive-buttons{
  display: none;
}

.sidebar-big-container .sidebar-header-simple {
  margin-bottom: 1rem;
}

.sidebar-header h5, .sidebar-header-simple h5 {
  font-family: 'Inter', 'raleway', sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: left;
}

.sidebar-cell-body-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
}

.address-filters-container input {
  background-color: #e8ecf0;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  padding: 25px 20px 25px 55px;
  position: relative;
  width: 100%;
}

.address-filters-container input::placeholder, .address-filters-container input {
  color: #7a8cb2;
}

.address-filters-container .input-with-image {
  position: relative;
}

.address-filters-container .input-with-image .fa-icon-holder {
  color: #757c83;
  font-size: 20px;
  left: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.address-filters-container .last-input {
  margin-top: 15px;
}

.filters-title {
  padding-bottom: 25px;
}

.clear-filters .clear-all-filters-button {
  background-color: transparent;
  border: solid 2px #70787d;
  border-radius: 5px;
  color: #36a7eb;
  /* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
  height: 4em;
  width: 100%;
}

.clear-filters .clear-all-filters-button:hover {
  background-color: #70787d;
  border-color: #70787d;
  color: #fff;
}

.sidebar-cell-container {
  align-items: center;
  display: flex;
  height: 90%;
  justify-content: flex-start;
  margin: auto;
  width: 90%;
}

.sidebar-range {
  position: relative;
}

.sidebar-range output {
  position: absolute;
}

.underbar {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.underbar p {
  text-align: center;
  width: 6%;
}

.sidebar-checkboxes-, .sidebar-checkboxes-religions {
  margin-top: 1.2rem;
}

.sidebar-checkboxes-religions {
  padding-left: 20px;
}

.sidebar-checkboxes- .sidebar-checkboxes-row, .sidebar-checkboxes-religions .sidebar-checkboxes-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.schools-counter {
  align-self: flex-end;
  color: #757c83;
}

.sidebar-checkboxes- .checkmark, .sidebar-checkboxes-religions .checkmark {
  box-shadow: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.checkmark {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #757c83;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.checkmark:after {
  color: white;
  content: "\f00c";
  display: none;
  font-family: "FontAwesome";
  font-size: 15px;
  font-weight: 900;
}

/* .checkmark:hover {
  background-color: #36a7eb;
  border: #36a7eb;
} */

/* .checkmark:hover + span {
  color: #36a7eb;
} */

.checkmark:checked {
  background-color: #0096ed;
  border: none;
}

.checkmark:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

.checkmark:disabled + span {
  color: #a5a5a5;
  cursor: default;
}

.checkmark:checked:after {
  display: block;
}

.label-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

.label-container span {
  cursor: pointer;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: normal;
  padding-left: 10px;
}

.sidebar-checkboxes-management {
  margin-top: 1.2rem;
}

.sidebar-checkboxes-management .sidebar-checkboxes-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.sidebar-checkboxes-management .checkmark {
  box-shadow: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

@media all and (max-width: 568px) {
  .sidebar-wrapper button {
    font-size: 10px;
  }

  .responsive-buttons{
    display: flex;
    align-items: center;
    background-color: 0;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .upper-buttons{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .down-buttons{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .fixed{
    position: fixed;
    top: 0;
    background-color: gray;
    width: 100%;
    height: 100px;
    box-shadow: 5px 5px 3px 0.1px rgb(0 0 0 / 20%);
    padding-left: 12px;
    padding-right: 12px;
    z-index: 998;
  }
  .save__filters__button{
    padding: 5px;
    height: 2.9em;
    border-radius: 0;
  }
  .sidebar-big-container.responsive-none{
    display: none;
  }
  
  .responsive-none{
    display: none;
  }
}