.react_modal_overlay {
    background: rgba(0, 0, 0, 0.5);
  
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header_keep {
    display: flex;
    align-items: center;
  }
  .header_keep h2{
    font-size: 20px;
    margin-top: 8%;
    margin-bottom: 5%;
    color: black;
    font-weight: 600;
  }
  
  .react_modal_content {
    width: 100%;
    max-width: 400px;
    height: 225px;
    background: white;
    padding: 0 2rem 3rem 2rem;
    position: relative;
    border-radius: 0.24rem;
  }
  
  .name_input {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.25rem;
  
    border: none;
    background: white;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.8);
  
    font-weight: 400;
    font-size: 1rem;
  
    margin-top: 1rem;
  }
  
  .modal-close {
    border: none;
    background: none;
    margin-left: auto;
  }
  
  .name_input::placeholder{
    color: rgba(0, 0, 0, 0.5);
  }
  
  .keep_button{
    /* margin-top: 1rem; */
    border: none;
    background-color: rgb(66,148,230);
    padding: 4px 12px;
    border-radius: 6px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: white;
    width: 100%;
    height: 3rem;
  }

  .mark{
    color: rgb(66,148,230);

    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: default;
  }
  
  .loading__container {
    margin-top: 1rem;
  }
  