.div__card__link {
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  /* cursor: pointer; */
  margin: 20px 0 0 0;
  width: 100%;
}

.div__card__link:hover {
  transform: scale(1.01);
  /* transition: 500ms; */
}

.blockCard-container {
  background-color: white;
  border-radius: 5px;
  margin: 0 0 0 0;
  min-height: 170px;
  max-height: 500px;
  padding: 0.8em;
  text-align: left;
}

.icons__container {
  /* background-color: tomato; */
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.icon {
  margin-right: 10px;
}

.margin__sides {
  margin: 0 5px;
}

.icon__text {
  margin-left: 5px;
}

.card__row {
  margin-top: 8px;
  margin-bottom: 10px;
}

.card__school__title {
  color: #333333;
  font-weight: 600;
  margin-bottom: 8px;
}

.card__school__title:hover {
  color: #333;
}

@media all and (max-width: 991px) {
  .icons__container {
    max-width: 100%;
  }
}
