.input__container {
  position: relative;
  min-width: calc(80% - 52px);
}

.vacantest__input {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  box-shadow: none;
  font-size: 1.2em;
  font-weight: 400;
  height: 40px;
  margin: 0;
  padding: 25px;
  min-width: 100%;
}

.vacantest__input:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

@media all and (max-width: 768px) {
  .input__container {
    min-width: calc(100% - 52px);
  }

  .dropdown__content {
    width: 100%;
  }
}
