.description__container,
.description__container h3 {
  color: #fff;
  font-family: "Montserrat","Helvetica Neue",Arial,sans-serif;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.4em;
  max-width: 500px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}

.paragraph__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.description__top__holder {
  align-items: center;
  display: flex;
  width: 100%;
}

.paragraph__container .icon__holder {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 20%;
}

.icon__holder {
  font-size: 1.7em;
}

.paragraph__container h3 {
  margin: 0;
  width: 80%;
}

.description__text {
  display: flex;
  justify-content: flex-start;
  margin-left: 20%;
}

.description__container p {
  color: hsla(0,0%,100%,.8);
  font-size: 0.8em;
  font-weight: 300;
  line-height: 1.61em;
  margin-top: 10px;
  max-width: 500px;
  width: 80%;
}

@media all and (max-width: 1024px) {
  .description__container p:last-child {
    margin-bottom: 0;
  }
}