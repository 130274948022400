.vacantest__card__content {
  /* background-color: darkturquoise; */
  min-height: 50%;
  padding: 0 10%;
}

.vacantest__card__content h3 {
  color: #333;
  font-weight: 600;
  margin: 5% 0;
  padding: 0;
}

.vacantest__card__content .dropdown__label {
  color: #bebec1;
  font-size: 1.3em;
  margin: 0;
  padding: 0;
}

.vacantest__card__content .dropdown__bolder__label {
  color: #333;
  font-size: 1.3em;
  margin: 10px 0;
  padding: 0;
}

.vacantest__card__content p, .vacantest__card__content ul{
  color: #333;
  font-size: 1.3em;
  margin: 5% 0;
  padding: 0;
}

.vacantest__location__button {
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 10px;
  color: #333;
  display: flex;
  font-size: 1.2em;
  font-weight: 600;
  padding: 10px 50px;
}

.vacantest__location__button:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.vacantest__location__button__icon {
  margin-top: -1px;
  margin-right: 10px;
}

.vacantest__card__content h4 {
  color: #333;
  font-weight: 600;
  margin: 5% 0 3% 0;
  padding: 0;
}

.loading__text {
  margin: 0 !important;
}

.vacantest__map__container {
  border: 1px #333 solid;
  height: 300px;
  margin: 5% 0;
  overflow: hidden;
  width: 100%;
}

.vacantest__map__loading {
  margin: 10px !important;
}

.vacantest__error__message {
  color: #dc3545 !important;
  margin: 0 !important;
}

.vacantest__section__container {
  margin: 50px 0;
  padding: 0;
}

.vacantest__checkbox__container {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}

.vacantest__checkbox__container > p {
  cursor: pointer;
}

.vacantest__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #757c83;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  height: 30px;
  display: flex;
  justify-content: center;
  outline: none;
  width: 30px;
  -webkit-appearance: none;
}

.vacantest__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-size: 20px;
  font-weight: 900;
}

.vacantest__checkbox:checked {
  background-color: #333;
  border: none;
}

.vacantest__checkbox:checked:after {
  display: block;
}

.vacantest__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

.vacantest__checkbox__label {
  margin: 0 0 0 5% !important;
}

.vacantest__plus__icon {
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  display: flex;
  height: 52px;
  justify-content: center;
  margin: 5px 5px 35px 0;
  width: 52px;
}

.vacantest__plus__icon:hover {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.vacantest__icon__holder {
  align-items: center;
  display: flex;
}

hr {
  margin: 20px 0;
}

@media all and (max-width: 767px) {
  .vacantest__card__content {
    padding: 0 5%;
  }

  .vacantest__container {
    padding: 0;
  }

  .vacantest__section__container {
    padding: 0;
  }
}
