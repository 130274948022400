.empty-experience {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  /* max-width: 982px; */
  width: 100%;
  margin: auto;
  padding: 20px 20px;

  &__welcome {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 432px;
    margin-top: -120px;

    .welcome__title {
      color: rgba(0, 0, 0, 0.90);
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;

      &--highlite {
        color: #0096ED;
        font-weight: 600;
        line-height: inherit;
      }
    }

    .welcome__subtitle {
      color: var(--Black-90, rgba(0, 0, 0, 0.90));
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      width: 350px;
    }
  }


  &__details {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 316px;
  }

  .detail-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }

  .detail-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }

  .detail {
    border-radius: 12px;
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;

    &__number {
      color: #0096ed;
      position: relative;
      width: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      position: relative;
    }

    &__title {
      color: #1A1A1A;
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;

    }

    &__text {
      color: #666666;
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;

    }
  }

  .experience__action {
    padding: 0px 29px 0px 29px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
}

@media all and (max-width: 900px) {
  .empty-experience {
    flex-direction: column;
    margin: auto auto auto auto;
    gap: 28px;

    &__welcome {
      width: 100%;
      margin-top: unset;

      .welcome__title {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
      }
    }

    &__details {
      gap: 28px;
      align-items: flex-start;
      justify-content: flex-start;
      width: 316px;

      .detail-list {
        gap: 6px;
      }
    }
  }
}