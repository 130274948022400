.row-then-column {
  display: flex;
  align-items: center;
  
  &.flex-row {
    flex-direction: row;
  }
  
  &.flex-column {
    flex-direction: column;
  }
}