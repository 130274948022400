.gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--gallery-height);
}

.gallery > button {
  display: none;
}

.gallery > ol {
  display: none;
}

.gallery > div > div > img {
  height: var(--gallery-height);
  width: calc(100% / 3);
  margin: 0px 5px;
}

.buttonsContainer {
  /* border: 1px solid green; */
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
}

@media screen and (max-width: 517px) {
  .gallery {
    overflow: hidden;
  }

  .gallery > div > div > img {
    height: var(--gallery-height);
    width: unset;
    margin: 0px;
  }
}

@media screen and (max-width: 850px) {
  .gallery {
    overflow: hidden;
  }
  .gallery > div > div > img {
    height: var(--gallery-height);
    width: unset;
    margin: 0px;
  }
}