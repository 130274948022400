.text__area {
  width: 100%;
  margin-left: 0;
  padding: 0;
  border: 1px solid black;
  height: 150px;
  resize: none;
  padding-left: 15px;
  padding-top: 5px;

  color: #333;
  font-family: 'Inter', 'raleway', sans-serif !important;
  font-size: 1.2em !important;
  font-weight: 400;
}

.text__area__label {
  font-weight: 600;
}

.parents__dropdown {
  width: 500px;
  background-color: black;
  height: 500px;
}
