.experience-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  backdrop-filter: blur(5px); /* Efecto de desenfoque */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.experience-modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: experience-modalFadeIn 0.3s ease-in-out forwards;
  padding: 20px 0;

  &--small {
    // width: 400px;
    max-width: 50%;
    max-height: 50%;
  }

  &--medium {
    max-width: 70%;
    max-height: 70%;
  }

  &--large {
    max-width: 90%;
    max-height: 90%;
  }

  &--full {
    width: 100%;
    height: 100%;
  }
}

.experience-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px 0px 20px;
}

.experience-modal--header-present {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.experience-modal-header__title {
  display: flex;
  flex-direction: column;
}

.experience-modal-title {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin: 0 0 4px 0;
}

.experience-modal-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  width: 80%;
  margin: 0;
}

.experience-modal-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: rgba(0,0,0,0.7);
}

.experience-modal-body {
  padding: 20px 40px 20px 40px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;

  /* .experience-modal--small & {
    height: 40vh;
  }

  .experience-modal--medium & {
    height: 60vh;
  }

  .experience-modal--large & {
    height: 75vh;
  } */
}

@keyframes experience-modalFadeIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}