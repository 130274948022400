.experience-progress-bar {
  border-radius: 50px;
  background-color: rgba(0,0,0, 0.1);
  height: 12px;
  flex: 1;

  &--progress {
    border-radius: 50px;
    background-color: rgb(0, 150, 237);
    height: 12px;
  }
}
