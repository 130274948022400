.page__title {
  margin-bottom: -30px;
}

.radio_inputs {
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.vacantest__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  height: 35px;
  display: flex;
  justify-content: center;
  outline: none;
  width: 35px;
  -webkit-appearance: none;
}

.vacantest__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-size: 20px;
  font-weight: 900;
}

.vacantest__checkbox:checked {
  background-color: #333;
  border: none;
}

.vacantest__checkbox:checked:after {
  display: block;
}

.vacantest__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

.break__line {
  margin-top: 15px;
  padding-bottom: 15px;
  font-weight: 900;
}

.costInput__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-top: 25px;
}

.constInput__container svg {
  margin: 0;
  padding: 0;
}

.cost_input {
  width: calc(80% - 51px);
  height: 50px;
  border: 0.3px solid black;
  box-shadow: none;

  text-align: start;
  padding: 25px;
}

.cost_input[type='text'] {
  font-size: 17px;
  font-weight: 400;
}

.bold__subtitle {
  font-weight: 600;
}
