
.experience-benefits-modal {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 22px;
  background-color: #FFFFFF;
  color: #1A1A1A;
  max-width: 544px;

  .experience-benefits-modal__title__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .experience-benefits-modal__title__image__container {
      display: flex;
      width: 160px;
      height: 160px;
      padding: 13.33px;
      
      .experience-benefits-modal__image {
        width: 133.33px;
        height: 133.33px;
      }
    }

    .experience-benefits-modal__title {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }
  }

  .experience-benefits-modal__content__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .experience-benefits-modal__content__item {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: row;
      gap: 22px;
      padding: 32px 0px 32px 0px;

      .experience-benefits-modal__content__item__image__container {
        display: flex;
        flex: 0 0 40px;
        width: 40px;
        height: 40px;
        border-radius: 75px;
        background-color: #F0F0F0;
        padding: 7px;

        .experience-benefits-modal__content__item__image {
          width: 25.8px;
          height: 25.8px;
        }
      }

      .experience-benefits-modal__content__item__details {
        display: flex;
        flex-direction: column;
        flex: 1;

        .experience-benefits-modal__content__item__title {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #1A1A1A;
        }
        .experience-benefits-modal__content__item__subtitle {
          font-size: 11px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;   
          color: #00000099;
        }
      }

    }

    .experience-benefits-modal__content__item:not(:last-child) {
      border-width: 0px 0px 1px 0px;
      border-style: solid;
      border-color: #DDDDDD;
    }
  }
}

@media (max-height: 824px) {
  .experience-benefits-modal {
    .experience-benefits-modal__content__container {
      .experience-benefits-modal__content__item {
        padding: 24px 0px 24px 0px;
      }
    }
  }
}
