#registeredSchool {
  .school-finder {
    display: flex;
    flex-direction: column;
    gap: 0!important;
    width: 600px;

    &__title {
      color: #000000;
      font: var(--body-xl-semibold, 600 20px/28px "Inter", sans-serif);
      position: relative;
      max-width: 508px;
    }

    &__school-list {
      display: flex;
      flex-direction: column;
      position: relative;
      left: 0;
      max-height: 50vh;
      gap: 8px;
      margin-top: 20px;

      &__title {
        color: #666666;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }

      .school-item {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        height: 80px;
        width: 100%;
        position: relative;
        border-radius: 8px;
        border-color: #DDDDDD;
        border-style: solid;
        border-width: 1px;
        color: rgba(0, 0, 0, 0.8);
        padding: 20px;

        .school-image {
          width: 40px;
          height: 40px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .school-item__info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: flex-start;
          justify-content: center;
          align-self: stretch;
          position: relative;

          .school-item__name__text {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;

            text-align: left;
            position: relative;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .school-item__address__text {
            color: #666666;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;

            text-align: left;
            position: relative;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          
        }

        .school-item__action {
          margin-left: auto;
          
          button {
            background-color: transparent;
            border: none;
            text-decoration: underline;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;

          }
        }
      }
    }

    @media screen and (max-width: 600px) {
      width: 100%;

      &__school-list {
        left: 0;
      }
    }
  }
}
