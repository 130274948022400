.list-selector {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  padding: 0 16px 16px;

  &__label {
    color: var(--black-90, rgba(0, 0, 0, 0.9));
    text-align: left;
    font: var(--body-m-regular, 400 14px/18px "Inter", sans-serif);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__list {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }

  .list-selector__item {
    border-radius: 100px;
    border-style: solid;
    border-color: var(--black-30, rgba(0, 0, 0, 0.2));
    border-width: 1px;
    padding: 8px 16px 8px 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    &--checked {
      border-color: var(--black-90, rgba(0, 0, 0, 0.9));
      border-width: 2px;
    }

    &__label {
      color: var(--black-90, rgba(0, 0, 0, 0.9));
      text-align: center;
      font: var(--body-m-regular, 400 14px/18px "Inter", sans-serif);
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}




