.characteristics {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.characteristics > img {
  width: 30px;
  margin-right: 10px;
}