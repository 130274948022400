
.checkbox-selector {
  background: #ffffff;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
  border-width: 1.5px;
  padding: 16px;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 400;

  &--checked {
    font-weight: 600;
    border-color: rgba(0, 0, 0, 0.9);
  }

  &--disabled {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  &__input {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }

  &__label {
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    flex: 1;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__checkboxbutton {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    accent-color: #000000;

    &:checked {
      border-color: black !important;
      background-color: black !important;
    }
  }

  .counter-selector {
    margin-top: 24px;
  }
}
