.experience-button {
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  background: #ffffff;
  color: #1A1A1A!important;
  border: 1.5px solid #B3B3B3;

  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;

  img, svg {
    width: 18px;
    height: 18px;
  }

  &.fit-content{
    width: fit-content;
  }

  .icon--stroke path {
    stroke: rgba(0, 0, 0, 0.9);
  }

  .icon--fill path {
    fill: rgba(0, 0, 0, 0.9);
  }

  &--small {
    padding: 10px 20px 10px 20px;

    &.experience-button--icon-only {
      padding: 10px 10px 10px 10px;
    }
  }

  &--medium {
    padding: 12px 24px 12px 24px;
    
    &.experience-button--icon-only {
      padding: 12px 12px 12px 12px;
    }
  }

  &--large {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;

    padding: 14px 24px 14px 24px;
    
    &.experience-button--icon-only {
      padding: 14px 14px 14px 14px;
    }
  }

  &--icon-only {
    span {
      display: none;
    }
  }

  &--primary {
    background: #0096ED;
    color: #ffffff!important;
    border: none;

    .icon--stroke path {
      stroke: #ffffff;
    }

    .icon--fill path {
      fill: #ffffff;
    }

    &.experience-button--disabled {
      .icon--stroke path {
        stroke: rgba(0, 0, 0, 0.2);
      }
  
      .icon--fill path {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &--success {
    background: #0096ed;
    color: #ffffff!important;
    border: none;

    .icon--stroke path {
      stroke: #ffffff;
    }

    .icon--fill path {
      fill: #ffffff;
    }

    &.experience-button--disabled {
      background-color: lighten(#0096ed, 90%);
      color:  lighten(#fff, 70%)!important;

      .icon--stroke path {
        stroke: rgba(0, 0, 0, 0.2);
      }
  
      .icon--fill path {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &--transparent {
    border: none;
  }

  &.experience-button--disabled {
    background-color: #eaeaea;
    color: #b3b3b3!important;
    border: none;

    .icon--stroke path {
      stroke: lighten(#000, 70%);
    }

    .icon--fill path {
      fill: lighten(#000, 70%);
    }
  }
}

.chevron-right {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
