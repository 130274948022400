
:root {
  --primary: #0096ed;
  --secondary: #36a7eb;
  --register__blue: #3e95e6;
  --font__roboto: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}

.card__container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  clear: both;
  display: flex;
  flex-direction: column;
  font-family: var(--font__roboto);
  margin: 1.5em 0;
  max-width: 420px;
  padding: 20px;
  align-items: center;
  margin: 0 auto;
}

.title {
  font-size: 1.75rem;
  margin: 20px 0px;
  font-family: inherit;
  font-weight: 600;
  text-align: center;
}
.text{
  width: 90%;
}

.subtitle {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.form {
  display: flex;
  width: 100%;
  flex-direction: column;
}

button.btn {
  align-items: center;
  display: flex;
  font-size: 0.95em;
  justify-content: space-around;
  margin: 0 0 10px 0;
  text-shadow: rgba(134, 134, 134, 0.063) 0.5px 0.5px 0.5px;
}

button.btn-google {
  background-color: rgb(248, 249, 250);
  border: 1px solid #e7e7e7;
  color: #666;
  font-weight: 700;
}

button.btn-facebook {
  background-color: #3b5998;
  border: 1px solid #e7e7e7;
  color: #fff;
  font-weight: 700;
}

button.btn-twitter {
  background-color: #1da1f2;
  border: 1px solid #e7e7e7;
  color: #fff;
  font-weight: 700;
}

button.btn:hover {
  color: white;
  background-color: rgb(68, 68, 68);
}

.google-icon {
  background-attachment: scroll;
  background-clip: text;
  background-image: conic-gradient(from -45deg, rgb(234, 67, 53) 110deg, rgb(66, 133, 244) 90deg, rgb(66, 133, 244) 180deg, rgb(52, 168, 83) 180deg, rgb(52, 168, 83) 270deg, rgb(251, 188, 5) 270deg);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.google-icon::before {
  content: "\f1a0";
}

.buttonSubmit {
  background-color: var(--register__blue);
  border: none;
  border-radius: 0.1875rem;
  color: #fff;
  font-family: var(--font__roboto);
  font-size: 1.05em;
  font-weight: normal;
  margin: 30px 0 0 0;
  padding: 10px;
  transition: 300ms;
}

.buttonSubmit:hover {
  background-color: var(--secondary);
  transition: 300ms;
}

input.input {
  border: 1px solid #e7e7e7;
  border-radius: 0.1875rem;
  box-shadow: none !important;
  color: #677897;
  font-weight: normal;
  padding: 10px;
  width: 100%;
}

input.input:focus {
  background-color: rgb(248, 249, 250);
  box-shadow: none !important;
}

.last__input {
  margin-top: 25px;
}

.split__or, .split__or__first {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
  padding: 0;
}

.split__or__first {
  margin-top: 0;
}

.split__or .split__text,
.split__or__first .split__text {
  font-family: var(--font__roboto);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.split__or::before {
  content: "" !important;
  display: block !important;
  width: 40% !important;
  height: 1px !important;
  background-color: rgb(221, 221, 221) !important;
  margin-right: 5% !important;
}
.split__or::after {
  content: "" !important;
  display: block !important;
  width: 40% !important;
  height: 1px !important;
  background-color: rgb(221, 221, 221) !important;
  margin-left: 5% !important;
}

.links__container {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
}

.form__link {
  margin: 30px 11px 0 11px;
  text-align: center;
}

.last__form__link {
  margin: 5px 11px 0 11px;
}

.form__link p, .last__form__link p, .link__btn {
  display: inline-block;
  font-family: var(--font__roboto);
  font-size: 1.1em;
  margin: 0;
}

.link__btn {
  background-color: transparent;
  border: none;
  color: var(--register__blue);
  cursor: pointer;
  font-weight: normal;
  margin-bottom: .9em;
}

.simple__link__btn {
  margin-left: .5em;
}

.link__btn:hover {
  color: var(--secondary);
}

.last__btn {
  margin: 0 !important;
}

.email__info {
  font-size: .9em;
  margin: 10px 11px 20px;
}

.btn__text {
  font-family: var(--font__roboto);
  font-size: 1.05em;
}

.btn__icon {
  font-size: 1.3em;
}

.invalid__input__text {
  color: #dc3545;
  font-size: .9em;
  font-weight: normal;
  margin: 0;
}

.successful__input__text {
  color: #28a745;
  font-size: .9em;
  font-weight: normal;
  margin: 0;
}

.invalid__input {
  background-color: #fffafa;
  border: 1px solid #dc3545;
  border-radius: 0.1875rem;
  box-shadow: none !important;
  color: #677897;
  font-weight: normal;
  padding: 10px;
  width: 100%;
}

.input__with__icon {
  align-items: center;
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
}

.input__icon {
  cursor: pointer;
  margin-left: 90%;
  position: absolute;
}

.last__input__icon {
  margin-top: 25px;
}

.password__strength {
  align-items: center;
  display: flex;
  margin: 0;
}

.password__strength svg {
  margin: 0 2px;
}

.password__strength .password__bar__msg {
  font-family: var(--font__roboto);
  font-size: .9em;
  margin-left: 10px;
}

.form__check__label {
  cursor: pointer;
  margin: 10px 0 0 0;
  text-transform: none !important;
}

.form__check__label input[type="checkbox"] {
  height: 17px;
  margin: 10px 5px 0 0;
  width: 17px;
}

.checkmark {
  box-shadow: none;
  cursor: pointer;
  height: 20px;
  min-width: 20px;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #757c83;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.checkmark:after {
  color: white;
  content: "\f00c";
  display: none;
  font-family: "FontAwesome";
  font-size: 15px;
  font-weight: 900;
}

.checkmark:checked {
  background-color: #3e95e6;
  border: none;
}

.checkmark:checked:after {
  display: block;
}

.checkmark__label {
  cursor: pointer;
  font-family: var(--font__roboto);
  font-size: 14px;
  padding: 0;
  width: 85%;
}

.checkmark__container {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkmark__container > * {
  margin: 10px!important;
}

.error__checkmark {
  box-shadow: none;
  cursor: pointer;
  height: 20px;
  min-width: 20px;
  appearance: none;
  -webkit-appearance: none;
  background-color: #fffafa;
  border: 1px solid #dc3545;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.logo__container {
  margin: 20px 0 30px 0;
  padding: 0;
  text-align: center;
}

.logo__container img {
  margin: auto;
  width: 10em;
}

.cardFree{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  clear: both;
  font-family: var(--font__roboto);
  margin: 2em auto;
  max-width: 420px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.cardImg{
  width: 5rem;
}

.cardContent{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cardContent p{
  font-weight: 300;
  color: black;
  width: 75%;
  margin: 0;
}

.cardButton{
  background-color: rgb(44,44,44);
  margin-top: 1rem;
  border: none;
  color: white;
  width: 100%;
  height: 2.3rem;
}

@media all and (max-width: 1024px) {
  .card__container {
    margin: 1.5em auto;
  }
}
