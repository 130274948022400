.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ratingContainer > span {
  margin-right: 5px;
}

.ratingContainer > legend {
  vertical-align: baseline;
  height: 24px;
  padding: 0px !important;
  margin-bottom: -5px;
  font-weight: bold;
}