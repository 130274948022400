.experience-main-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  overflow-y: auto !important;
  flex-grow: 1;
  min-height: calc(100vh - 69px);
  min-height: calc(100dvh - 69px);
  height: calc(100vh - 69px);
  height: calc(100dvh - 69px);

  &.with-footer {
    min-height: calc(100vh - 130px - 69px);
    min-height: calc(100dvh - 130px - 69px);
    height: calc(100vh - 130px - 69px);
    height: calc(100dvh - 130px - 69px);
  }

  .experience-benefits-banner-container {
    background-color: #ffffff;
    padding: 20px 32px 20px 32px;
    border-top: 4px solid #0096ED;
    border-radius: 12px;
    margin: 40px 32px 0 32px;
    box-shadow: 1px 2px 40px 0px #0000001F;
  }

  &.experience-main-content--mobile .experience-benefits-banner-container {
    padding: 20px 20px 20px 20px;
    margin: 28px 20px 0 20px;
  }
  
  .experience-body {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: calc(100vh - 69px);
    height: calc(100dvh - 69px);
    
    padding: 40px 32px;
    /* position: relative; */
    
    &--mobile {
      padding: 28px 20px;
    }
    
    &.with-footer {
      height: calc(100vh - 130px - 69px);
      overflow-y: auto;
    }

    /* .blur-overlay {
      position: absolute;
      height: 32px;
      bottom: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
    } */
  }
}