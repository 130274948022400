.locateUserButton {
  background-color: transparent;
  border: none;
  color: #70787d;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  text-decoration: underline;
  /* text-transform: capitalize; */
  width: 100%;
}

.locateUserButton:hover {
  color: #36a7eb;
  text-decoration: none;
}

/* .input_up{
  color: black;
} */

.findMeCrosshairs {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 15px 0 0 0 !important;
}

.error__container .error__link {
  color: #36a7eb;
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 1em;
}

.error__container .error__link:hover {
  color: #0096ed;
}

.error__container .error__msg {
  background-color: #fffafa;
  border: 0.5px solid #dc3545;
  border-radius: 3px;
  color: #dc3545;
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 1em;
  margin-top: 15px;
  padding: 5px 15px;
  text-align: left;
  width: 100%;
}

.find__me {
  position: relative;
}

.find__me__tooltip {
  background: #70787d;
  border-radius: .3rem;
  color: white;
  content: 'teste';
  left: 0%;
  max-width: 90%;
  padding: .5rem;
  position: absolute;
  text-align: left;
  top: 1.5rem;
  transform: translateX(-50%), translateY(-100%);
  width: max-content;
  visibility: hidden;
  opacity: 0;
}

.find__me__tooltip p {
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 1.1em;
  margin: 0;
  padding: 0;
}

.find__me:hover .find__me__tooltip {
  visibility: visible;
  opacity: .9;
  transition: 500ms;
}

.dropdown__predictions {
  position: relative;
  font-weight: bold;
}

.dropdown__predictions__content {
  background-color: white;
  box-shadow: 5px 5px 12px 0.1px rgb(0 0 0 / 20%);
  z-index: 1;
  border-radius: 12px;
}

.dropdown__predictions__container {
  background: #ffffff;
  border: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  left: 50%;
	overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: left;
  transform: translateX(-50%);
  z-index: 5;
}

.dropdown__predictions__container p {
  line-height: normal;
}

.dropdown__predictions__header {
  align-items: center;
  background-color: #9cc1d7;
  display: flex;
  margin: 0;
  min-height: 50px;
  padding: 0;
  width: 100%;
}

.dropdown__predictions__header p {
  color: #333333;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-break: normal;
  margin: 0;
  padding: 10px 20px;
  white-space: normal;
}

.dropdown__predictions__body {
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.dropdown__predictions__body button {
  background-color: transparent;
  border: none;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 20px;
  text-align: left;
  transition: 0.3s;
}

.dropdown__predictions__body button:hover {
  color: #36a7e8;
  padding-left: 25px;
  transition: 0.3s;
}

.dropdown__predictions__body p {
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.address__filters__container input {
  background-color: #e8ecf0;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  padding: 25px 20px 25px 55px;
  position: relative;
  width: 100%;
}

.address__filters__container input::placeholder, .address__filters__container input {
  color: #7a8cb2;
}

.address__filters__container .input__with__image {
  position: relative;
}

.address__filters__container .input__with__image .fa__icon__holder {
  color: #757c83;
  font-size: 20px;
  left: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.input__with__image input {
  font-size: 1.1em !important;
}

.address__filters__container .last__input {
  margin-top: 15px;
}

.predictions__button {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1.1em !important;
  font-weight: 400;
}
