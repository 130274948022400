.page__title {
  margin-bottom: -30px;
}

.radio_inputs {
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.vacantest__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  height: 35px;
  display: flex;
  justify-content: center;
  outline: none;
  width: 35px;
  -webkit-appearance: none;
}

.vacantest__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-family: "FontAwesome";
  font-size: 20px;
  font-weight: 900;
}

.vacantest__checkbox:checked {
  background-color: #333;
  border: none;
}

.vacantest__checkbox:checked:after {
  display: block;
}

.vacantest__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

.text__area {
  width: 100%;
  margin-left: 0;
  font-size: 18px;
  padding: 0;
  border: 1px solid black;
  height: 150px;
  resize: none;
  padding-left: 15px;
  padding-top: 5px;
  font-family: 'Inter', 'raleway', sans-serif !important;
}

.text__area::placeholder {
  color: black;
  font-weight: 400;
  font-size: 18px;
}

.break__line {
  margin-top: -5px;
  font-weight: 600;
}

.text__title {
  font-weight: 600;
}

.text__description {
  color: black;
  white-space: nowrap;
}
