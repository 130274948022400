@keyframes aparecerDesdeAbajo {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.address-selector {
  max-width: 600px;
  display: flex;
  flex-direction: column;

  &__title {
    color: #000;
    font-weight: 600;
    font-size:  20px;
  }

  &__subtitle {
    color: #000000;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
  }

  &__search {
    display: flex;
    gap: 20px;

    .input-search__container {
      flex: 1;
    }
  }

  &__button {
    padding: 10px 4px 10px 4px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    background: transparent;
    border: none;
    text-decoration-line: underline;

    .my-location {
      flex-shrink: 0;
      position: relative;
      overflow: visible;
    }

    .button__label {
      color: var(--black-90, rgba(0, 0, 0, 0.9));
      text-align: center;
      font: 600 14px/18px "Inter", sans-serif;
      text-decoration: underline;
      position: relative;
    }
  }

  &__list {
    box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &__item  {
    background: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-bottom: none;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }

    &:first-of-type {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &__map {
    height: 300px;
    overflow: hidden;
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
  }

  .location__range__input { 
    background: transparent;
  }

  .address-selector__list {
    z-index: 8;
    position: absolute;
  }

  .address-selector__item:hover {
    background: #dcdcdc;
  }
}


.location__range__container {
  // position: fixed;
  /* bottom: -10px;
  height: 0px; */
  display: none;
  z-index: -99;
  /* visibility: hidden; */
  background-color: #ffffff;
  width: 95%;
  margin: -70px auto 0 auto;
  border: 1px solid #DDDDDD;
  box-shadow: 2px 4px 20px 0px #00000014;
  border-radius: 12px;
  padding: 20px;
  
  &.shown {
    display: block;
    z-index: 99;
    /* visibility: visible; */
    animation: aparecerDesdeAbajo 0.3s ease-in-out;
    /* position: fixed;
    bottom: -10px;
    height: 0px; */
  }
}

.location__range__title h5 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.location__range {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 20px auto 0;
  width: 100%;
}

.sidebar-range {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 40px auto 0;
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;

  .range__slider {
    color: rgb(0, 150, 237);
    height: 8px;
    & .MuiSlider-track {
      border: none;
    }
    & .MuiSlider-thumb {
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 2px solid currentColor;
      
      &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
        box-shadow: inherit;
      }
      &::before {
        display: none;
      }
    }
    & .MuiSlider-markLabel {
      font-weight: 600;
    }
    & .MuiSlider-valueLabel {
      /* line-height: 1.2px; */
      font-size: 12px;
      font-weight: 600;
      background: unset;
      /* padding: 0;
      width: 32px;
      height: 32px; */
      border-radius: 8px;
      background-color: rgb(0, 150, 237);
      /* transform-origin: bottom left;
      transform: translate(50%, -100%) rotate(-45deg) scale(0);
      &::before { 
        display: none;
      }
      &.MuiSlider-valueLabelOpen {
        transform: translate(50%, -100%) rotate(-45deg) scale(1);
      }
      & > * {
        transform: rotate(45deg);
      } */
    }
  }
}

.location__range__input {
  box-shadow: none !important;
  height: 29px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  margin: 10px 0 !important;
  width: 100% !important;
}

.location__range {
  position: relative;
}

.location__range__underbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.location__range__underbar .smaller__range {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;

  margin: 0;
  padding: 0;
  width: 6%;
}

@media all and (max-width: 767px) {
  .location__range__container {
    width: 90%;
  }
}