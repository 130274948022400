.experience-footer {
  display: flex;
  flex-direction: column;
  max-height: 125px;
  gap: 24px;
  padding: 24px 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px -4px 16px 0px #00000014;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }

  &__progress {
    display: flex;
    gap: 20px;
  }
  @media screen and (max-width: 600px) {
    padding: 24px 20px;
  }
}
