.experience-congrats {
  max-width: 432px;
  margin: auto auto auto auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 480px) {
    width: 80vw ;
  }

  &__icon {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: visible;
    margin: auto;
  }

  &__message {
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    align-self: stretch;
  }

  &__form {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 100%;

    &__toggle {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      position: relative;
      width: 100%;

      &__content {
        width: 100%;
        visibility: hidden;

        &.shown {
          visibility: visible;
        }
      }
    }

    .button-toggle {
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      position: relative;
      display: flex;
      margin-bottom: 24px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .chevron {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      position: relative;
      overflow: visible;
    }
  }

  &__separator {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;

    .separator__line {
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      border-width: 1px 0 0 0;
      flex: 1;
      height: 0px;
      position: relative;
    }

    .separator__line {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      position: relative;
    }
  }

}

.result-loading {
  margin-top: 230px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.experience-congrats__providers {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.experience-congrats__form__email {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 100%;
}

.congrats__form__input {
  /* border: 1.5px solid #B3B3B3 */
  border-radius: 8px!important;
  border-color: #B3B3B3;
}

.button-send  {
  border-radius: 44px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #B3B3B3;
}