.iconRowContainer {
  width: 100%;
  padding: 10px;

}

.iconRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
}

.iconContainer {
  display: flex;
  flex: 1 1 33vw;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 10px;
  
  
}

.iconRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.imgCol {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50%;
}

.imgContainer {
  width: 55;
  height: 55;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  /* padding: 5px; */
  /* background-color: #ccc; */
}

.imgContainerImg {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .iconContainer {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    padding: 10px;
    max-width: unset;
  }

  .iconContainer .iconRow {
    width: 100% !important;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    
  }
  
  .iconContainer .iconRow .imgCol {
    display: block;
    width: 150px;
  }

  .iconContainer .iconRow .imgCol .imgContainer {
    display: flex;
    width: 50px !important;
    height: 50px !important;
    justify-content: center;
    align-items: center;
  }

  .iconContainer .iconRow .imgCol .imgContainer img{
    max-width: 150px !important;
    max-height: 150px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 150px;
    background-color: #ccc;
  }
}