* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto'; */
}

.vacantestbanner {
  width: 100%;
  background-color: #ffffff;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; */
  box-shadow: rgba(55, 67, 190, 0.1) -4px 9px 25px -6px;
  margin: 15px;
  padding: 18px;
}

.vacantestbanner > * {
  margin: 10px 0px;
}

.vacantestbanner > h1, .vacantestbanner > h3 {
  width: 80%;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: rgba(0,0,0,0.8);
}

.vacantestbanner > h1 {
  font-size: 28px;
}

.vacantestbanner > h3 {
  font-size: 18px;
}

.vacantest-button {
  padding: 8px 14px;
  background: #4294E6;
  border-radius: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  max-width: 180px;
  border: none;
}

@media all and (max-width: 991px) {
  .vacantestbanner h1, .vacantestbanner h3 {
    font-weight: 400;
  }
  .vacantestbanner h1 {
    font-size: 22px;
  }
  .vacantestbanner h3 {
    font-size: 14px;
  }
  .vacantest-button {
    font-weight: 400;
    font-size: 13px;
  }
}

@media all and (max-width: 722px) {
  .vacantestbanner h1, .vacantestbanner h3 {
    width: 90%;
  }
}
