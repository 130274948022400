.experience-page__title {
  color: #1A1A1A;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  width: 100%;
  margin: 0;
  display: flex;
  gap: 12px;
}

.experience-page__subtitle {
  color: #666666;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
  margin-top: 8px;
  white-space: pre-line;
}