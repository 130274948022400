@use "sass:math";
@import './breakpoint';

$base-font-size: 16px;

* {
    box-sizing: border-box;
}

@each $container, $maxContainer in $containers {
    @for $i from 1 through $grid-columns{ 
      .grid-#{$container}-#{$i} {
          @include breakpoint($container) {
            width: (math.div(100%, ($grid-columns ))* $i)
          };
        }
      }
}

.text {
  @include breakpoint('md') {
    font-weight: normal;
  }
  @include breakpoint('lg') {
    font-weight: bolder;
  }
}

.body__content {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 1440px;
  min-height: calc(100vh - 200px);
  width: 100%;
}

.background {

  background-image: url('../../../../assets_vacanted/login_school.jpg');
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  /* top: 69px; */
}


.description__container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 55%;
}

.form__container {
  width: 45%;
}

.footer__container {
  width: 100%;
}

@media all and (max-width: 1023px) {
  .description__container,
  .form__container {
    width: 100%;
  }
}
