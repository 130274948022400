* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin:auto;
  background-color: #f7f9fc;
}

.footer-container {
  margin-top: 10px;
}

.heart-bg {
  display: flex;
  background: rgb(247, 249, 252);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  border-radius: 50%;
  width:35px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.heart-bg-filled {
  display: flex;
  background: var(--blue);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  border-radius: 50%;
  width: 24px;
  height: 22px;
  justify-content: center;
  align-items: center;
}

.nav-header-wrapper {
  height: 100px;
  margin: auto;
  width: 100%;
}

.nav-image-wrapper {
  height: 300px;
  position: relative;
}

.nav-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0 20%;
}

.nav-image-wrapper p {
  position: absolute;
  top: 35%;
  padding-left: 13%;
  color: rgb(255, 255, 255);
  font-size: 3rem;
  width: 100%;
}

.card-map-container {
  margin-top: 25px;
  width: 100%;
}

.sidebar-address {
  margin: 25px 0 0 0;
  width: 100%;
}

.sidebar-filters {
  background-color: #ffffff;
  margin: 10px 0 0 0;
  width: 100%;
}

.sidebar {
  padding:10px;
  background-color: black;
  min-height:300px;
}

.main {
  min-height:300px;
}

.form-group {
  padding: 20px;
  padding-bottom: 0px;
  margin-bottom: 0;
}

input.form-control {
  height: 50px;
  background-color: #e8ecf0;
  max-width: 350px;
  border-color: rgba(0,0,0,0.1);
  margin: auto;
}

div.Reveal-exlip-sidebar {
  padding: 15px;
  background-color: #fff;
  max-height: 250px;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
}

.body-wrapper {
  margin: auto;
  max-width: 1440px;
  width: 90%;
}

.empty-search-container {
  background-color: #ffffff;
  margin: 10px 0 0 0;
  text-align: left;
}

@media all and (max-width: 768px) {
  .nav-header-wrapper {
    align-items: center;
    height: 65px;
    justify-content: center;
  }

  .nav-image-wrapper {
    height: 170px;
    object-fit: cover;
    object-position: 0 20%;
  }
}

@media all and (max-width: 568px) {
  .body-wrapper {
    margin: auto;
    width: 100%;
  }
  /* .sidebar-wrapper {
    display: none;
  } */
  .smart__filters{
    display: none;
  }
  .sidebar-header{
    display: flex;
    justify-content: center;
  }
  div.body-content, div.sidebar-wrapper {
    padding-right: 7px;
    padding-left: 7px;
  }
  .sidebar-title{
    display: none;
  }

  .turn_filters{
    background-color: #333333;
    color: white;
    border: none;
    padding: 8px;
    /* border-radius: 5px ; */
    font-weight: 600;
    width: 153px;
  }

  .nav-image-wrapper p {
    top: 45%;
    font-size: 18px;
    text-align: center;
    padding-left: unset;
  }
}

@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
  .nav-image-wrapper p {
    text-align: center;
    width: auto;
    margin-left: 280px;
  }
}
