.info-box-message {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  &.with-margin-top {
    margin-top: 20px;
  }

  &.with-margin-bottom {
    margin-bottom: 20px;
  }

  &__icon {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex: 1;
    position: relative;
    color: rgba(0,0,0,0.8);
    font-size: 12px;
    font-weight: 500;
  }
}
