.recommendations {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  .pre-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    margin-top: 40px;

    .no-profiles-message {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
  
      color: #1A1A1A;
    }
  }
}

.serach-profile-recommendations {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  border-radius: 8px 8px 0 0;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 20px;
  padding-bottom: 20px;
}

.serach-profile-recommendations__header {
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  border-style: solid;
  border-color: #DDDDDD #DDDDDD #666666 #DDDDDD;
  border-width: 1px 1px 0px 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  box-shadow: 1px 4px 24px 0px rgba(0, 0, 0, 0.12);

  hr {
    height: 4px;
    width: 100%;
    background-color: #666666;
    margin: 0!important;
  }
}
.header__container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 80px;
  position: relative;
}
.header__info {
  padding: 1px 0px 1px 0px;
  display: flex;
  flex-direction: row;
  gap: 64px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  .header__datas {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;

    .header__data {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      &.date {
        width: 190px;
        min-width: 190px;
        margin-right: 5%;
        flex: unset;
      }
    
      img {
        width: 16px;
        max-width: 16px;
        height: 16px;
        max-height: 16px;
      }
    }
  }
}
.header__search-profile-name {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.header__search-profile-name__text {
  color: rgba(0, 0, 0, 0.9);
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ellipse {
  background: #d9d9d9;
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.search-profile-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.search-profile-data__value {
  color: #1A1A1A;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
}

.school-item .school-data-actions-container .school-item__actions .school-item__actions__main__buttons button {
  min-width: 180px;
}

@media (max-width: 1120px) {
  .header__container {
    padding: 16px 16px 12px 16px;

    .header__info {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
    
      .header__datas {
        width: 100%;
    
        .header__data {
        
          img {
            width: 16px;
            max-width: 16px;
            height: 16px;
            max-height: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .header__info .header__datas .header__data {
    flex: 1 1 0;
    &.date {
      flex: 1 1 0;
      min-width: unset;
      width: unset;
      margin-right: unset;
    }
  }
}

@media (max-width: 512px) {
  .header__info .header__datas {
    flex-direction: column;
    align-items: flex-start;

    & .header__data {
      width: 100%;
    }
  }
}

@media (max-width: 330px) {
  .school-item .school-data-actions-container .school-item__actions .school-item__actions__main__buttons button {
    min-width: unset;
  }
}