.vacantest__masked {
  background-color: #333;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  width: 100%;
  z-index: 2000;
}

.close__test__modal__container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
}

.close__modal__actions {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.close__test__modal {
  background-color: #fff;
  border: 2px solid #58a6e1;
  max-width: 50%;
  padding: 25px;
  position: fixed;
  text-align: center;
  z-index: 2500;
}

.close__test__modal h3 {
  color: #333;
  font-weight: 600;
  margin: 5% 0;
  padding: 0;
}

.close__test__modal p {
  color: #333;
  font-size: 1.3em;
  margin: 5% 0;
  padding: 0;
}

.close__modal__test button {
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2em;
  font-weight: 600;
  margin: 5% 0;
  padding: 10px 50px;
}

.close__modal__btn__container {
  background-color: transparent;
  margin: -25px;
  padding: 0;
  position: absolute;
  width: 100%;
}

.close__modal__btn {
  align-items: center;
  background-color: #58a6e1;
  border: none;
  border-radius: 50%;
  display: flex;
  color: #fff;
  height: 40px;
  justify-content: center;
  margin-left: calc(100% - 40px);
  top: -45px;
  position: relative;
  width: 40px;
  z-index: 2400;
}

.vacantest__container {
  background-color: #fff;
  min-height: 100vh;
}

.vacantest__header {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  min-height: 15vh;
  position: sticky;
  top: 0;
  z-index: 1300;
}

.vacantest__header__top {
  /* background-color: cornflowerblue; */
  display: flex;
  width: 100%;
}

.vacanted__logo__container {
  align-items: center;
  /* background-color: darkmagenta; */
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 50%;
}

.vacanted__logo {
  margin-left: 2%;
  max-height: 70px;
  max-width: 70px;
  min-height: 60px;
  min-width: 60px;
  width: 10%;
}

.vacanted__logo__container p {
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 0 2%;
  padding: 0;
}

.vacantest__close {
  align-items: center;
  /* background-color: darkorange; */
  display: flex;
  height: 100%;
  justify-content: flex-end;
  text-align: center;
  width: 50%;
}

.vacantest__close button {
  background-color: transparent;
  border: none;
  color: #677897;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0 2% 0 0;
  padding: 0;
}

.vacantest__percentage__bar__holder {
  background-color: #bebec1;
  display: flex;
  width: 100%;
}

.vacantest__percentage__bar {
  align-items: center;
  background-color: #58a6e1;
  display: flex;
  justify-content: flex-end;
  transition: 1s ease;
}

.vacantest__percentage__value {
  /* background-color: tomato; */
  color: #fff;
  font-size: 1.4em;
  font-weight: 400;
  margin: 0 5px 0 0;
  transition: 1s ease;
}

.vacantest__dynamic__content {
  /* background-color: blueviolet; */
  display: flex;
  min-height: 85vh;
}

.vacantest__card {
  /* background-color: darkorange; */
  display: flex;
  width: 55%;
}

.vacantest__empty {
  /* background-color: darksalmon; */
  width: 20%;
}

.vacantest__filled {
  /* background-color: darkslateblue; */
  width: 80%;
}

.vacantest__actions__desktop, .vacantest__actions__mobile {
  align-items: center;
  /* background-color: darkred; */
  display: flex;
  justify-content: space-between;
  padding: 5% 10%;
  width: 100%;
}

.vacantest__actions__mobile {
  display: none;
}

.vacantest__actions__desktop p {
  color: #333;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.vacantest__next__button {
  background-color: #58a6e1;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2em;
  font-weight: 600;
  margin-left: auto;
  padding: 10px 50px;
  transition: 0.1s ease;
}

.vacantest__next__button:hover {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  opacity: 0.9;
  transition: 0.1s ease;
}

.vacantest__next__button:disabled {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  opacity: 0.5;
}

.vacantest__back__cta {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #333;
  display: flex;
  justify-content: flex-start;
}

.vacantest__back__cta:hover {
  opacity: 0.8;
}

.vacantest__card__tips {
  background-color: #eeeeee;
  width: 45%;
}

.vacantest__tip {
  border: 2px solid #bebec1;
  margin: 5% 15%;
  width: 50%;
}

.vacantest__tip__icon {
  /* background-color: lawngreen; */
  height: 30%;
}

.vacantest__tip__icon img {
  min-width: 60px;
  margin: 3%;
  max-width: 100px;
}

.vacantest__tip__title {
  /* background-color: lightcoral; */
  color: #333;
  padding: 3% 5%;
  width: 100%;
}

.vacantest__tip__title p {
  font-size: 1.3em;
  font-weight: 600;
  margin: 0;
}

.vacantest__tip__content {
  /* background-color: lightpink; */
  padding: 3% 5%;
  width: 100%;
}

.vacantest__tip__content p {
  margin: 0 0 30px 0;
}

@media all and (max-width: 768px) {
  .close__test__modal__container {
    align-items: center;
  }

  .close__test__modal {
    margin: 0;
    max-width: 90%;
  }

  .vacantest__header {
    position: sticky;
    top: 0;
    z-index: 1300;
  }

  .vacanted__logo {
    margin-left: 10%;
  }

  .vacanted__logo__container p {
    margin: 0 0 0 10%;
  }

  .vacantest__close button {
    margin: 0 10% 0 0;
  }

  .vacantest__percentage__value {
    margin-right: 2px;
  }

  .vacantest__dynamic__content {
    flex-wrap: wrap;
  }

  .vacantest__card {
    width: 100%;
  }

  .vacantest__empty {
    display: none;
    width: 0;
  }

  .vacantest__filled {
    width: 100%;
  }

  .vacantest__actions__desktop {
    display: none;
  }

  .vacantest__actions__mobile {
    display: flex;
    padding: 5%;
  }

  .vacantest__actions__mobile p {
    margin: 0;
    font-weight: 600;
    padding: 0;
  }

  .vacantest__card__tips {
    width: 100%;
  }

  .vacantest__tip {
    margin: 5% auto;
    width: 90%;
  }
}
