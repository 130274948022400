.toggle-container {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  justify-content: space-between;
  margin: 25px 0 0 0; 
  padding: 10px;
  width: 100%;
}

.nr__of__schools {
  /* background-color: tomato; */
  margin: 5px;
  width: 30%;
}

.nr__of__schools p {
  font-size: 15px;
  font-weight: 300;
  margin: 0 !important;
}

.toggle__buttons__container {
  align-items: center;
  /* background-color: turquoise; */
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

.toggle__select {
  align-items: center;
  background-color: #e8ecf0;
  border: 1px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  height: 40px;
  justify-content: space-around;
  margin: 5px;
  width: 35%;
}

.label__toggle__select {
  line-height: 1.61em;
  margin: 0 0 0 5px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.order-container {
  display: flex;
  justify-content:center;
  background-color: #e8ecf0;
  border: none;
  border-radius: 2px;
  height: 45px;
  width: 100%;
  max-width: 250px;
}

.sort-toogle-select {
  background-color: transparent;
  border: none;
  color: #676565;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.sort-toogle-select {
  cursor: pointer;
}

.map__toggle__button {
  background-color: #e8ecf0;
  border: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  height: 40px;
  margin: 5px;
  padding: 5px 15px;
}

.toggle__buttons_container {
  margin: 0 !important;
}

@media all and (max-width: 1439px) {
  .nr__of__schools {
    /* background-color: tomato; */
    width: 45%;
  }

  .toggle__buttons__container {
    /* background-color: teal; */
    width: 50%;
  }

  .toggle__select {
    width: 50%;
  }

  .toggle__line__break {
    margin: 5px !important;
    width: 100%;
  }
}

@media all and (max-width: 767px) {
  .toggle__select {
    justify-content: flex-start;
    height: 50px;
    width: 100%;
  }

  .label__toggle__select {
    margin-right: 10px !important;
  }

  .map__toggle__button {
    height: 50px;
  }
}

@media all and (max-width: 568px) {
  .nr__of__schools p {
    font-size: 12px;
  }
  .toggle__buttons__container {
    font-size: 10px;
    width: 52%;
  }

  }
