.textarea-selector {
  background: #ffffff;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
  border-width: 1.5px;

  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  color: rgba(0, 0, 0, 0.9);
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  flex: 1;
  min-height: 110px;
  max-height: 110px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  resize: none;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.textarea-selector:focus, .textarea-selector:focus-visible {
  border-color: rgba(0, 0, 0, 0.9);
  outline: none !important;
}