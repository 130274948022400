// Gutters
$gap-xsmall: 4px;
$gap-small: 8px;
$gap-medium: 16px;
$gap-large: 24px;
$gap-xlarge: 32px;
$gap-xxlarge: 48px;
$gap-xxxlarge: 64px;

$snackbar-color: #ffffff;
$snackbar-error-bg-color: #ff6262;
$snackbar-info-bg-color: #323232;

$font-size-medium: 0.875rem; //14px
$font-size-large: 1rem; //16px

.snackbar {
    position: fixed;
    bottom: $gap-medium;
    left: 50%;
    transform: translateX(-50%);
    color: $snackbar-color;
    padding: $gap-medium $gap-xxlarge;
    border-radius: 4px;
    font-size: $font-size-medium;
    z-index: 9999;
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &--error {
        background-color: $snackbar-error-bg-color;
    }

    &--info {
        background-color: $snackbar-info-bg-color;
    }
}

.snackbar:hover {
    opacity: 1;
}

.snackbar-message {
    display: block;
    text-align: center;
}

.snackbar-close {
    position: absolute;
    top: $gap-small;
    right: $gap-small;
    background: none;
    border: none;
    font-size: $font-size-large;
    color: $snackbar-color;
    cursor: pointer;
    padding: 0;
}
  