.vacanted__mui__textfield {
  .MuiInputLabel-root {
    &.MuiInputLabel-shrink.Mui-focused {
      color: rgba(0, 0, 0, 0.9);
    }

    &:not(.MuiInputLabel-shrink) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  & .MuiInputBase-root {
    border-radius: 8px;
    border-color: #72777F;

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.9);
    }
  }

  input {
    // padding: 4px 0px 4px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.5px;
  }
}