
.support-logos {
  text-align: center;
  background-color: #FFFFFF;
  padding: 100px 0;
}

.support-logos {
  padding: 80px 40px 80px 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  background-color: #fff;

  &__logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 20px;

    justify-content: center;
    flex-shrink: 0;
    position: relative;
  
    @media all and (max-width: 630px) {
      flex-direction: column;
    }
  }
}

.support-logos__title {
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  position: relative;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;

}
