.dropdown {
  position: relative;
  user-select: none;
}

.dropdown__button {
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 25px;
  width: 80%;
}

.dropdown__button p {
  color: #333;
  font-size: 1.2em !important;
  font-weight: 400;
  margin: 0;
}

.dropdown__icon {
  align-items: center;
  border: 1px solid #333;
  display: flex;
  height: 52px;
  justify-content: center;
  margin-right: -26px;
  width: 52px;
}

.dropdown__content {
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
  padding: 10px 25px;
  position: absolute;
  top: 110%;
  width: calc(80% - 51px);
  z-index: 1200;
}

.dropdown__button:hover, .dropdown__content:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dropdown__button_disabled:hover {
  box-shadow: none;
}

.dropdown__item {
  background-color: transparent;
  border: none;
  color: #677897;
  cursor: pointer;
  display: flex;
  font-size: 1.2em;
  font-weight: 400;
  padding: 10px 0;
  text-align: start;
  transition: all 0.2s;
  width: 100%;
}

.dropdown__item:hover {
  background-color: #eee;
}

@media all and (max-width: 768px) {
  .dropdown__button {
    width: 100%;
  }

  .dropdown__content {
    width: calc(100% - 51px);
  }
}
