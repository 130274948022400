.empty-search-container {
  align-items: center;
  display: flex;
  padding: 10px;
  width: 100%;
}

.empty-search-container p {
  margin: 0 0 0 5px;
}

@media all and (max-width: 991px) {
  .loading-schools-spinner-container {
    margin-bottom: 0;
    margin-top: 25px;
  }
}