.recommendations {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  .pre-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    margin-top: 40px;
  
    // Pending
    .actions-carousel {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  
    .candidate-count {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
  
      color: #1A1A1A;
    }
  }
}

.button {
  background: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  padding: 10.5px 20px 10.5px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  position: relative;
}

.experience-candidates {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  border-radius: 8px 8px 0 0;
  color: rgba(0, 0, 0, 0.8);
  padding: 20px 0;
}