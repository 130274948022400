.isActive {
  color: #006699;
  transition: all 0.5s ease-in-out;
  height: 120px;
  overflow: none;
}

.isInactive {
  height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.subtitleSection {
  padding: 0px 10px;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
}
