.line {
  font-size: 10;
  margin: 5 0;
}

.sectionLanguageDiscription {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.sectionLanguageDiscription > section > p {
  /* border: 1px solid red; */
  margin: 0 0 5px 0;
}

div p.circularRowTitle {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

div p.circularRowText {
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
}