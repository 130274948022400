.experience-congrats {
  max-width: 432px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &__icon {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: visible;
    margin: auto;
  }

  &__message {
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    align-self: stretch;
  }

  &__submessage {
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &__action {
    width: fit-content;
  }

}