.searchbar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 36px;
  position: relative;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
  position: absolute;
}

.input-icon {
  z-index: 1;
  position: absolute;
  left: 16px;
}

.input-searchbar {
  /* border: 1.5px solid #B3B3B3; */
  box-shadow: none;
  background-color: #E6E8EE;
  border: none;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 400;
	height: 36px;
  margin: 0;
	overflow: hidden;
  padding: 15px 15px 15px 40px;
  display: flex;
  width: 320px;
  border-radius: 20px;
}

.input-searchbar::placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  opacity: 0.7;
}

.input-searchbar:focus {
  outline: none;
}

.input-search {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #36a7e8;
  cursor: default !important;
  display: flex;
  font-size: 14px;
  padding: 10px;
  position: absolute;
  right: 5px;
}

.input-icons-separator {
  background-color: transparent;
  color: #757c83;
  filter: opacity(30%);
  position: absolute;
  right: 29px;
  transform: rotate(90deg);

}

.input-close {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #757c83;
  cursor: pointer;
  display: flex;
  margin-right: -16px;
  position: absolute;
  right: 40px;
  z-index: 3;;
}

.input-close:hover svg {
  color: #36a7e8;
}

.dropdown-container, .dropdown-message {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-items: center;
  background: #ffffff;
  border: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  padding: 0;
  position: relative;
  text-align: left;
  top: 48px;
  width: 320px;
  z-index: 101;
  border-radius: 20px;
  max-height: 75svh;
  overflow: auto;

  * {
    position: relative;
    z-index: 101;
  }
}

.dropdown-message {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px;
  text-align: center;
}

.dropdown-message p {
  color: #7a8cb2;
  font-family: 'Raleway',sans-serif;
  font-size: 15px;
  margin: auto;
}

.dropdown-container p {
  line-height: normal;
}

.dropdown-content {
  margin: 0;
  padding: 0;
}

.dropdown-header {
  align-items: center;
  background-color: #9cc1d7;
  display: flex;
  margin: 0;
  min-height: 50px;
  padding: 0;
  width: 100%;
}

.dropdown-header p {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  line-break: normal;
  margin: 0;
  padding: 10px 20px;
  white-space: normal;
}

.dropdown-body {
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.dropdown-body button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  text-align: left;
  transition: 0.3s;
}

.dropdown-body button:hover {
  color: #36a7e8;
  padding-left: 25px;
  transition: 0.3s;
}

.dropdown-body p {
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.dropdown-schools {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
}

.dropdown-body-school {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0 20px 0;
  text-align: left;
  transition: 0.3s;
}

.dropdown-body-school:hover {
  color: #36a7e8;
  padding-left: 5px;
  transition: 0.3s;
}

.dropdown-body-school:hover p {
  color: #36a7e8;
}

.dropdown-body-school p {
  margin: 0;
  padding: 0 10px;
}

.dropdown-body-school .school-info {
  color: #adb5bd;
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
}

.dropdown-body-school .school-name {
  color: #333333;
  font-family: 'Inter', 'raleway', sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
}

.dropdown-body-school .school-name .school-city {
  font-weight: 400;
}

.input-btn button {
  border: none;
}

