.facebook-button {
  border-radius: 44px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.90);
  border-width: 1px;
  padding: 12px 18px 12px 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  gap: 10px;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  background-color: #fff;

  &:disabled {
    background-color: rgb(198 198 198);
  }
}
