.terms__and__conditions__content {
  background-color: white;
  min-height: calc(100vh - 100px);
  font-size: 16px;
  margin-bottom: 30px;
  padding: 0 10%;
}

.img_content {
  height: 250px;
  height: 300px;
  position: relative;
  
  .img_file {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 10%;
  }
}

.text_content {
  padding: 0 10%;

  ul, ol {
    padding-left: 2em;
    
    li {
      list-style: disc;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 12px 0;
  }

  h1 {
    margin-top: 24px;
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }
}
