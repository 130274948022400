.page__container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 100px - 182px - 32px);
  text-align: center;
}

.page__container h3 {
  margin: 0;
  padding: 0;
}