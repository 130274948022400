.input__container {
  position: relative;
}

.vacantest__input {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  box-shadow: none;
  font-size: 1.2em;
  font-weight: 400;
  height: 40px;
  margin: 0;
  padding: 25px;
  width: 80%;
}

.vacantest__input:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dropdown__content {
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
  padding: 10px 25px;
  position: absolute;
  top: 110%;
  width: 80%;
  z-index: 1100;
}

.dropdown__item {
  background-color: transparent;
  border: none;
  color: #677897;
  cursor: pointer;
  display: flex;
  font-size: 1.2em;
  font-weight: 400;
  padding: 10px 0;
  text-align: start;
  transition: all 0.2s;
  width: 100%;
}

.dropdown__item:hover {
  background-color: #eee;
}

.loading__text {
  font-size: 1.1em !important;
  margin: 0 !important;
}

@media all and (max-width: 768px) {
  .vacantest__input {
    width: 100%;
  }

  .dropdown__content {
    width: 100%;
  }
}
