.existing-experience {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 584px;
  padding-bottom: 20px;

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &__icon {
      
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;

    }
  }


  &__details {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    .detail-list {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__separator {
        width: 100%;
        height: 0px;
        border: 1px solid #DDDDDD;
      }
      
      .detail {
        display: flex;
    
        &__title {
          color: #666666;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
        }
        
        &__value {
          color: #1A1A1A;
          display: flex;
          flex-direction: column;
    
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.1px;
          text-align: right;
  
          span {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            text-align: inherit;
          }
        }
  
        &.flex-row {
          & .detail__value {
            margin-left: auto;
            align-items: flex-end;
          }
        }
  
        &.flex-column {
          & .detail__value {
            margin-left: unset;
            align-items: center;
          }
        }
      }
    }
  
  }

  &__explanation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: #F0F0F0;
    padding: 16px;

    &__icon {}

    &__text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: center;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 8px 0px 8px 0px;

      &__question {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
      }
      
      &__option {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        
        & > span {
          font-weight: 600;
        }
      }
    }
    
    &__buttons {
      display: flex;
      gap: 12px;

      &.flex-column button {
        width: 100%;
      }
    }
  }
}