.chipContainer {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.chipContente {
  display: flex;
  flex-direction: row !important;
  background-color: #eceff1;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px !important;
  margin-right: 10px;
  padding: 5px 12px !important;
  width: auto;
}

.chipContainer p {
  margin: 0px;
  color: rgba(0,0,0,.6) !important;
  font-weight: 500;
  font-size: 12px !important;
}

.chipContainer svg {
  cursor: pointer;
}