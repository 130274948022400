:root {
  --gallery-height: 300px;
}

.container {
  max-width: 100%;
  margin: 20px 5%;
  z-index: 2 !important;
}

/* div.container h3,
div.container h2,
div.container h1,
div.container h4 {
  font-family: 'Inter', 'raleway', sans-serif;
}

div.container p, div.container a, div.container button
 {
  font-family: 'Roboto', sans-serif;
} */

.main button {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid var(--dark);
  color:var(--dark);
}

.empty__gallery {
  background-color: #0096ed;
  height: 100px;
  width: 100vw;
}

.gallery__container {
  /* background-color: tomato; */
  height: var(--gallery-height);
  width: 100vw;
  overflow-x: hidden;
  z-index: -1;
  position: relative;
  height: 100%;
}

.buttons__Container > div {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -170px;
  margin-bottom: 170px;
  padding: 0 20px;
  height: auto;
}

.buttons__Container > div > button {
  border: none;
  background-color: unset;
  padding: 0 !important;
  margin: 0;
  width: unset;
  height: unset;
}

.buttons__Container > div > button > svg {
  font-size: 2rem;
  color: rgba(145, 145, 145, 0.8);
}

.picture__gallery > div:last-child {
  /* border: 1px solid red; */
  z-index: 10;
  position: absolute;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.pageWrapper {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.mainWrapper {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.sticky {
  position: sticky;
  top: 79px;
}

.main {
  width: 65%;
  height: 100%;
  margin-right: 2%;
}

.asideWrapper {
  display: flex;
  justify-content: center;
  padding: 0;

  width: 100%;
}

.aside {
  width: 100%;
}

.aside__action__btns {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.aside__action__btns button:disabled {
  background-color: #e8ecf0;
  border: #e8ecf0;
  color: #fff;
  cursor: default;
}

.asideContainer button {
  width: 100%;
}

.asideContainer {
  background-color: #fff;
  padding: 10px;

  border-radius: 4px;
}

aside.aside div.asideContainer p {
  font-size: 0.95em;
  margin-bottom: 2px;
}

aside.aside div.asideContainer .last__p {
  font-size: 0.95em;
  margin-bottom: 0;
}

.asideBtn button {
  /* background-color: var(--primary); */
  padding: 10px;
  /* font-size: 0.85rem; */
  /* text-transform: uppercase; */
  /* border-color: var(--primary); */
  /* border-radius: 3px; */
  margin-bottom: 8px;
  /* color: white; */
  /* font-weight: 500; */
}

.transparentActionBtn > button {
  border: 1px solid #333 !important;
  background-color: unset !important;
  border-radius: 3px;
  text-align: center;
  padding: 10px;
}

.transparentActionBtn > button > p {
  text-transform: uppercase;
  font-weight: 500 !important;
  color: #333;

  margin: 0 !important;
  padding: 0;
}

div .asideContainer button.black {
  background-color: var(--dark);
  border-color: var(--dark);
}

div .asideContainer button.transparent {
  background-color: #fff;
  color: var(--dark);
  border-color: var(--dark);
}

.aside p {
  line-height: 14px;
  font-size: 15px;
  padding: 2px;
  font-weight: 600;
}

.asideBtn {
  color: #0096ed !important;
  border: none !important;
  background-color: transparent !important;

  padding: 0 !important;
  margin: 0 !important;

  text-transform: none !important;
  text-align: start;
}

.asideBtn:disabled {
  cursor: default;
}

.asideBtn:disabled p {
  color: #677897;
}

.asideWrapper .aside .aside__action__btns__mobile {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-evenly;
  align-items: center;
}

.asideWrapper .aside .aside__action__btns__mobile button {
  padding: 10px 14px 10px 14px;
  max-height: 38px;
}

.action_stack {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.main_action button {
  width: 100%;
}

.asideWrapper .aside .aside__action__btns .rest_actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.asideWrapper .aside .aside__action__btns__mobile .rest_actions {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.asideWrapper .aside .rest_actions {
  width: 100%;
}

.asideWrapper .aside .rest_actions button {
  flex: 1 1 auto;
}

@media only screen and (max-width: 740px) {
  .footer-container {
    padding-bottom: 30px;
  }

  .container {
    margin: 20px 10px;
  }

  .mainWrapper > .main {
    /* border: 1px solid red; */
    width: 100% !important;
    margin-right: 0;
    
  }

  .mainWrapper > div:last-child {
    width: 100% !important;
    position: fixed !important;
    z-index: 4;
    left: 0;
    bottom: 0;
  }

  .buttons__Container > .buttons_view_port_mobile {
    margin-top: -40%;
    margin-bottom: 40%;
    display: flex;
  }

  .buttons__Container > .buttons_view_port_desktop {
    display: none;
  }

  .asideWrapper .aside > .asideContainer {
    background-color: #f1f1f1;
    border-radius: 8px 8px 0px 0px;
  }

  .asideWrapper .aside > .asideContainer > .asideContainer > div {
    background-color: #fff;
  }

  .asideWrapper .aside .asideContainer .asideBtn,
  .asideWrapper .aside .asideContainer > hr {
    display: none;
  }

  .transparentActionBtn {
    display: none!important;
  }
}