.header-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  position: static;
  top: 0;
  z-index: 990;
  height: 69px;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  padding: 24px 40px 24px 40px;
  background-color: #fff;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 0px 0px 1px 0px;

  &.header-container--fixed {
    position: fixed;
  }

  .header-title {
    display: flex;
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #1A1A1A;
  }

  .logo-container {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
  
    .logo-home-button {
      display: flex;
      gap: 12px;
      flex: 0 0 auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      border: none;
      margin: 0;
  
      img {
        height: 37px;
      }
      
      .flag-icon {
        width: 29px;
        height: 29px;
        background-size: cover;
      }
      .flag-icon[data-country="uy"] {
        background-image: url("https://vacanted-assets-dev.s3.amazonaws.com/icons/flags/flag_uy.png");
      }
      .flag-icon[data-country="ar"] {
        background-image: url("https://vacanted-assets-dev.s3.amazonaws.com/icons/flags/flag_ar.png");
      }
      .flag-icon[data-country="mx"] {
        background-image: url("https://vacanted-assets-dev.s3.amazonaws.com/icons/flags/flag_mx.png");
      }
      .flag-icon[data-country="pe"] {
        background-image: url("https://vacanted-assets-dev.s3.amazonaws.com/icons/flags/flag_pe.png");
      }
    }
  }
  
  .logo-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  
    .logo-home-button {
      gap: 4px;
      img {
        height: 45px;
        width: 45px;
      }
    }
  }
  
  .searchbar-container {
    display: flex;
    width: 0px;
    height: 0px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: -100;

    &--visible {
      height: 69px;
      opacity: 1;
      width: 320px;
      z-index: 100;
    }

    &--full-width {
      width: 100vw;
      position: fixed;
      left: 0;
      top: 0;
      background-color: white;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      border-width: 0px 0px 1px 0px;
    }
    
    .searchbar-wrapper {
      height: 36px;
    }
  }

  .rigth-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 0 0 auto;
    margin-left: auto;


  }
}

.singing-chevron {
  transition: all .3s;
}

.singing-chevron--open {
  transform: rotate(-180deg);
  transition: all .3s;
}

.slidebar-toggle {
  align-items: center;
  justify-content: center;

  i {
    color: #1f1f1f;
    font-size: 25px;
  }
  
  .slidebar-btn {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
  }
}

@media all and (max-width: 1023px) {
  .header-container {
    gap: 24px;
    padding: 12px 24px 12px 24px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 255px;
  box-shadow: 5px 5px 12px 0.1px rgb(0 0 0 / 20%);
  z-index: 1;
  border-radius: 12px;

  &.dropdown--open {
    display: block;
    right: 0;
    top: 60px;
  }
  
  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
  }

  .dropdown__btn {
    background-color: white;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 12px;
    height: 50px;
    padding: 10px;
    width: 100%;

    .type-login-icon {
      margin: 0 8px;
    }

    .item-text {
      font-weight: bold;
      margin: 0 0 0 8px;
      font-weight: bold;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }

    &:hover p {
      color: #36a7e8;
    }
    
    &:first-child {
      display: flex;
      align-items: center;
      border: none;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    
    &:last-child {
      display: flex;
      align-items: center;
      border: none;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

