.school-item {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: solid 1px #DDDDDD;
  border-radius: 12px;
  padding: 20px;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  width: 100%;

  &--highlighted {
    border-width: 1.5px;
    border-color: #087dc1;
  }
  
  &--actionable {
    cursor: pointer;
  }

  .school-image-container {
    display: flex;
    position: relative;
    height: 100%;
    min-width: 60px;
    width: 60px;
    flex: 0;
    
    .school-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      border-radius: 8.91px;
      border: 1.11px solid #DDDDDD;
    }
  }
  .school-data-actions-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex: 1;

    .school-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 12px;
      
      .school-basic-info {
        display: flex;
        /* flex: 1 1 auto; */
        width: 100%;

        .school-item__name {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
          justify-content: center;
          align-self: stretch;
          position: relative;
          
          .school-item__name__text {
            color: rgba(0, 0, 0, 0.9);
            text-align: left;
            text-decoration: underline;
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
  
            img {
              width: 24px;
              height: 24px;
            }
          }
          .school-item__scores {
            padding: 4px 0px 4px 0px;
            display: flex;
            flex-direction: row;
            gap: 1px;
            align-items: center;
            justify-content: flex-start;
            flex-shrink: 0;
            width: 100%;
            height: 20px;
            position: relative;
  
            .school-item__affinity {
              padding: 0px 12px 0px 0px;
              display: flex;
              height: 100%;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              align-self: stretch;
              flex-shrink: 0;
              position: relative;
            
              .school-item__affinity-bage {
                background: #008264;
                border-radius: 24px;
                padding: 1px 8px 1px 8px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                position: relative;
                color: #ffffff;
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
              }
            }
            
            .school-item__rating {
              display: flex;
              flex-direction: row;
              align-items: center;
              min-height: 100%;

              i {
                height: 18px;
                width: 18px;
              }
              
              .school-item__rating__count {
                color: var(--black-60, rgba(0, 0, 0, 0.6));
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin-left: 4px;
              }
            }
          }
          
        }
      }

      .school-features {
        display: flex;
        /* flex: 1; */
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;

        .school-feature {
          display: flex;
          flex: 1;
          flex-direction: row;
          gap: 4px;

          font-size: 13px;
          font-weight: 500;
          line-height: 14px;
          text-align: left;

          color: #1A1A1A;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 18px;
            /* max-width: 18px; */
            height: 18px;
            /* max-height: 18px; */
          }
        }

        .school-item__distance {
          display: flex;
          flex: 2 1 0;
        }

        .school-item__address {
          flex: 2 1 0;
        }
        .school-item__public {
          flex: 2 1 0;
        }
        
        .school-item__religion {
          flex: 2 1 0;
        }
        
        .school-item__languages {
          flex: 2 1 0;
          flex-direction: row;
          justify-content: flex-start;
          gap: 4px;
        
          .flag-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            flex-shrink: 0;
            border-radius: 50%;
            height: auto;
            box-sizing: border-box;
        
            .flag {
              position: absolute;
              width: 18px;
              max-width: 18px;
              min-width: 18px;
              height: 18px;
              max-height: 18px;
              min-height: 18px;
              border: 1.2px solid #ffffff;
              border-radius: 50%;
              height: auto;
              box-sizing: border-box;
            }
            
            .flag-1 {
              left: 0;
              z-index: 4;
            }
            
            .flag-2 {
              left: 12px;
              z-index: 3;
            }
            
            .flag-3 {
              left: 24px;
              z-index: 2;
            }

            .flag-4 {
              left: 36px;
              z-index: 1;
            }

            .flag-5 {
              left: 48px;
              z-index: 0;
            }
          }
        
          .flag-container-1 {
            width: 18px;
            min-width: 18px;
          }
          .flag-container-2 {
            width: 30px;
            min-width: 30px;
          }
          .flag-container-3 {
            width: 42px;
            min-width: 42px;
          }
          .flag-container-4 {
            width: 54px;
            min-width: 54px;
          }
          .flag-container-5 {
            width: 66px;
            min-width: 66px;
          }
        
          .description {
            /* right: 0; */
          }
        }
      }
    }
    
    .school-item__actions {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      position: relative;
      
      .school-item__actions__main__buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;

        button {
          width: 100%;
        }
      }

      .school-item__actions__secondary__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        flex-shrink: 0;

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .school-item {
    
    .school-image-container {
      min-width: 52px;
      width: 52px;
      
      .school-image {
        width: 52px;
        height: 52px;
      }
    }
    .school-data-actions-container {
      flex-direction: column;
      gap: 16px;

      .school-info {
        gap: 16px;
        .school-basic-info {
          .school-item__name {
            
          }
        }
        .school-features {
          flex: 1 0 auto;
          .school-item__distance,
          .school-item__public,
          .school-item__religion {
            
          }
          .school-item__languages {
            
            .flag-container {
              
            }
          }
        }
      }
      .school-item__actions {
        flex: 1;

        .school-item__actions__main__buttons {
          flex-direction: row;
          flex: 1;
          gap: 8px;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
  
          button {
            width: auto;
            display: flex;
            flex: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .school-features {
    flex-direction: column;
  }
}

@media (max-width: 570px) {
  .school-item .school-data-actions-container .school-item__actions .school-item__actions__main__buttons {
    flex-direction: column;
    
    button {
      width: 100%;
    }
  }
}
