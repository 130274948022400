
.experience-benefits-warning-modal {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 32px;
  background-color: #FFFFFF;
  color: #1A1A1A;
  // max-width: 544px;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image__container {
      display: flex;
      width: 160px;
      height: 160px;
      padding: 13.33px;
      
      .experience-benefits-warning-modal__image {
        width: 133.33px;
        height: 133.33px;
      }
    }

    &__text {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      margin-bottom: 0;

      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      width: 100%;
      gap: 32px;

      &__text {
        width: 100%;

        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        gap: 12px;

        button.experience-button {
          width: 100%;
        }
      }

    }

    .experience-benefits-modal__content__item:not(:last-child) {
      border-width: 0px 0px 1px 0px;
      border-style: solid;
      border-color: #DDDDDD;
    }
  }
}
