.btn__send {
  background-color: var(--register__blue);
  border: none;
  border-radius: 0.1875rem;
  color: #fff;
  font-family: var(--font__roboto);
  font-size: 1.05em;
  font-weight: normal;
  margin: 10px 0 0 0;
  padding: 10px;
  transition: 300ms;
}

.btn__send:hover {
  background-color: var(--secondary);
  transition: 300ms;
}

.card__footer__link {
  display: flex;
  justify-content: space-around;
  margin: 30px 11px 0 11px;
}
