.accordion__Container {
  box-shadow: 1px 1px 4px 0.8px rgba(0, 0, 0, 0.2);

  margin: 2px 0;
  color: #303030;
  font-size: 12px;
  background-color: #ffffff;

}

.accordion__Container_active {
  margin: 15px 0;
}



.accordion__Container p {
  font-weight: 400;
}

.accordion__Container_active {
  padding:10px 0;
}



.isActive {
  color: #006699;
  transition: all 0.3s ease-in-out;
  height: auto;
  overflow: none;
}

.isInactive {
  height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.disabled {
  color: rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 0.1px 0.1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
  background-color: #e0e0e0;

}

.enabled {
  box-shadow: 1px 1px 0.1px 0.1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin: 0;
  padding: 10px;



}

.contentContainer {
  background-color: #ffffff;
  flex-direction: row;
  margin-left: 0;
  margin-right: 0;
}

div div.rotate {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

div div.rotationDefault {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}