.slide-menu {
  background: #ffffff;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 0px 1px 0px 0px;
  border-top: none;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 240px;
  min-width: 240px;
  min-height: calc(100vh - 69px);
  height: calc(100vh - 69px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &--collapsable {
    position: fixed;
    z-index: 99;
    top: 69px;
    left: -240px;
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  &--show {
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
    left: 0;
  }

  .menu-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;

    .single-button {
      padding: 16px 24px 16px 24px;

      button {
        width: 100%;
      }
    }

    &--rest {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      flex: 1 0 auto;
    }
  }
}
.experience-info {
  padding: 32px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 240px;
  position: relative;
  
  .experience-info-title {
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .experience-info-datas {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .experience-info-data {
      .experience-info-data-title {
        font-size: 11px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #666666;
      }

      .experience-info-data-value {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        color: #1A1A1A;
      }
    }
  }
  
  .tag {
    width: 52px;
    height: 18px;
    padding: 0px 8px 0px 8px;
    gap: 10px;
    border-radius: 24px;
    opacity: 0px;
  
    background: #F0F0F0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  
    .label {
      color: #007CC3;
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  padding: 0px 8px 0px 8px;
  
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    
    .item-menu {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      flex-shrink: 0;
      position: relative;

      .action {
        color: rgba(0, 0, 0, 0.9);
        text-align: left;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &--selected {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.05);
        .action {
          font-weight: 600;
        }
      }

      .item-menu-button {
        border-width: 0px;
        background-color: unset;
        padding: 12px 16px 12px 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
      
        &--selected {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
.menu-icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.divider {
  align-self: stretch;
  flex-shrink: 0;
  height: 2px;
  position: relative;
  
  .rectangle-533 {
    background:rgba(0, 0, 0, 0.05);
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
  }
}
