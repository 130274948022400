footer {
  background-color: #191f2b;
  color: white;
  text-decoration: none;
}

.footer_bottom_social{
  display: flex;
  list-style: none;
}

.footer_bottom_social li{
  width: 40px;
  height: 50px;
  margin-left: 16px;
}

.footer_bottom_social li img{
  max-width: 100%;
  max-height: 100%;
}

.footer_bottom_social li:first-child{
  margin: 0px
}

.footer_delimiter {
  margin: auto;
  max-width: 1440px;
}

.brand_cta {
  padding-bottom: 20px;
  padding-top: 10px;
}

.footer_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.sessions_wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-right: 60px;

  .footer_menu {
    padding-right: 40px;
  }
}

.text_center {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.footer_line {
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .brand_cta {
    font-size: 20px;
    text-align: center;
  }

  .footer_bottom_social {
    display: flex;
    justify-content: center;
  }

  .sessions_wrapper {
    margin-left: 75px;
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
  }

  .text_center {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .bullet_points {
    display: none;
  }

  .footer_line {
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 20px;
  }
}
