
@keyframes aparecerDesdeAbajo {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.experience-snackbar__container {
  width: 100%;
  position: fixed;
  display: none;
  z-index: 999;

  &--show {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    left: 0;
    animation: aparecerDesdeAbajo 0.3s ease-in-out;
  }
}

.experience-snackbar {
  border-radius: 4px;
  padding: 0px 16px 0px 16px;
  max-width: 450px;
  position: relative;
  box-shadow: var(
    --shadows-down-shadow-medium-box-shadow,
    0px 8px 16px 0px rgba(16, 4, 35, 0.12)
  );
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #333;
  bottom: 0px;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 425px){
    width: 85vw;
  }

  &--info {
    background: #1a1a1a;
  }

  &--success {
    background: #008264;
  }

  &--error {
    background: #ac1942;
  }
}

.experience-snackbar__icons {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.experience-snackbar__icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.experience-snackbar__message {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.experience-snackbar__text {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.experience-snackbar__action {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  
  .experience-snackbar__button {
    border: none;
    padding: 0;
    background: transparent;
    text-decoration: underline;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    color: inherit;
    font-weight: 500;
  }
}

