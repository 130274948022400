.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "school-card school-card school-card school-card"
    "school-card school-card school-card school-card"
    ". . . ."
    ". . . ."
    ". . . .";
}
.school-card {
  display: grid;
  grid-template-columns: 0.9fr 1.6fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Logo Infos like-contact"
    "Logo Infos like-contact";
  grid-area: school-card;
}

.icons {
  display:inline-block;
}

.icons-container-wrapper {
  margin-top: 12px;
  text-align: left;
}

.card-button {
  background-color: #36a7eb;
  color: white;
  font-weight: bolder;
  border-radius: 24px;
  border-color:none;
  border: none;
  outline: none;
  padding: 0.3em 1.0em;
}

.card-button-disabled{
  background-color: rgb(229, 229, 229);
  color: white;
  font-weight: bolder;
  border-radius: 24px;
  border-color:none;
  border: none;
  outline: none;
  padding: 0.3em 1.0em;

  cursor: default !important;
}

.icon-card-container {
  border: 2px solid rgb(103, 120, 151);
  border-radius: 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  width:28px;
}

.distance__span {
  margin-left: 4px;
}

.like-contact {
  display: flex;
}

.contact {
  position: absolute;
}

.Logo { grid-area: Logo; }
.Infos { grid-area: Infos; }
.like-contact { grid-area: like-contact; }

.card-img {
  height: 8em;
  width: auto
}

.school__card__contact__button {
  margin: 0 !important;
}

.school__card__contact__button__disabled{
  margin: 0 !important;
}

.school__card__contact__button:hover {
  background-color: #0096ed;
}

.card__last__row {
  margin-top: 10px;
  gap: 16px;
}
.card__last__row .actions {
  flex-direction: row;
  gap: 8px;
}

.school__card__address__row {
  display: flex;
  flex-direction: column;
}

.address__row {
  margin: 0 !important;
  padding: 0 !important;
}

.school__card__address {
  padding: 0 !important;
}

.card__address{
  margin-left: 4px;
}

.card__distance__icon {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

@media all and (max-width: 800px) {
  .card__last__row .actions {
    flex-direction: column;
  }
}

@media all and (max-width: 580px) {
  .card__last__row {
    flex-direction: column;
  }
  .card__last__row .reviews {
    width: 100%;
    justify-content: start;
  }
  .card__last__row .actions {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
}

@media all and (max-width: 420px) {
  .card__last__row .actions {
    flex-direction: column;
  }
}