* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin:auto;
  background-color: #f7f9fc;
}

.page-title {
  margin: 20px 0 0 0;
  color: rgba(0,0,0,0.9);
  width: 100%;
}

.page-subtitle {
  margin: 10px 0 0 0;
  color: rgba(0,0,0,0.6);
  width: 100%;
  font-size: 18px;
}

.body-content {
  width: 100%;
}

.leaflet-container {
  max-width: none!important;
}

.toggle-container {
  background-color: transparent!important;
}

.nr__of__schools p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0,0,0,0.6);
}

.main-wrapper .footer-container {
  margin-top: 10px;
}

.toggle-container > div {
  margin: 0px;
  padding: 0px 10px;
  width: 50%;
}

.heart-bg {
  display: flex;
  background: rgb(247, 249, 252);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  border-radius: 50%;
  width:35px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.heart-bg-filled {
  display: flex;
  background: var(--blue);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  border-radius: 50%;
  width: 24px;
  height: 22px;
  justify-content: center;
  align-items: center;
}

.card-map-container {
  margin-top: 25px;
  width: 100%;
}

.body-wrapper {
  margin: auto;
  max-width: 1440px;
  width: 85%;
  margin-bottom: 32px;
}

.body-wrapper > * {
  padding-right: 15px;
  padding-left: 15px;
}

.empty-search-container {
  background-color: #ffffff;
  margin: 10px 0 0 0;
  text-align: left;
}

@media all and (max-width: 1156px) {
  .body-wrapper {
    width: 90%;
  }
  .page-title {
    font-size: 32px;
  }
}

@media all and (max-width: 568px) {
  .body-wrapper {
    margin: auto;
    width: 100%;
  }
  
  div.body-content, div.sidebar-wrapper {
    padding-right: 7px;
    padding-left: 7px;
  }

  .page-subtitle {
    display: none;
  }
}
