.content {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.active p{
    padding: 15px 35px;
    border: 1px solid black;
    font-weight: bold;
    color: black;
    margin-top: 20px;
}

.active p button{
    border: none;
    background: none;
    font-weight: bold;
}

.bottom{
    margin-top:20px;
}


.email  {
    margin-top: 0;
    color: #36a7eb;
}
