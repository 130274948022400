.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: #333333;
}

.header-contact {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 7px;
}

h2.modal-header {
  display: block;
  color: #130d33;
  font-size:1.5rem;
  align-items: left;
  margin: 0;
  padding-left: 0;
  border-bottom: none;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}

.react-modal-content .modal-info-header {
  font-size: 0.85rem;
  font-weight: 600;
  text-align: left;
}

.react-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 800px;
  max-width: 570px;
  background: white;
  padding: 1rem 3rem 3rem 3rem;
  position: relative;
  border-radius: 0.24rem;
  overflow-y: scroll;
  height: 99%;
}

.react-modal-content::-webkit-scrollbar{
  border: none;
  width: 7px;
}

.react-modal-content::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: rgb(206, 206, 206);
}

.contact-input {
  width: 100%;
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem;
  border: none;
  background: white;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.3);
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
}

.contact-input__error {
  background-color: #fffafa;
  border: 1px solid #dc3545 !important;

  width: 100%;
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.3);
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
}

.contact-input:disabled {
  background-color: #e8ecf0;
  border: 1px solid #757c83;
  cursor: default !important;
}

.contact-btn {
  width: 100%;
  padding: 0 1.5rem;
  height: 3rem;
  background: #0096ed;
  color: white;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  margin-top: 2.5rem;
  font-weight: 700;
  transition: filter 0.2s;
}

.contact-btn:hover {
  filter: brightness(0.9);
}

.modal-close {
  /* position: absolute; */
  text-align: right;
  right: 1.5rem;
  top: 1.5rem;
  border: 0;
  background: transparent;
  margin-right: -3rem;
  transition: filter 0.2s;
  height: 35px;
}

.modal-close:hover {
  filter: brightness(0.7);
}

.contact-container {
  /* margin: 1rem 0; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.contact-container input[type='checkbox']{
  position: relative;
  cursor: pointer;
}

.contact-container input[type='checkbox']::before{
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border-radius: 3px;
  background-color: transparent;
}

.contact-container input[type='checkbox']:checked:before{
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border: 1px solid #555555;
  border-radius: 3px;
  background-color: #0096ed;
}

.contact-container input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 8px;
  height: 12px;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(30deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.contact-container > div:not(.contact-container-phone) {
  display: flex;
  /* margin-bottom: 10px; */
  padding: 0;
  flex-direction: column;
}

.contact-container > div:not(.contact-container-phone) div {
  display: flex;
  /* margin-bottom: 10px; */
  padding: 0;
  flex-direction: column;
}

.contact-container div.contact-container-phone {
  margin-top: 1rem;
}

.contact-container div.contact-container-phone, .contact-container div.contact-container-check {
  display: flex;
  flex-direction: row;
}

.contact-container div.contact-container-phone input {
  font-size: 14px;
  padding: 12px 0;
}

.contact-container div input.contact-input,
.contact-container div textarea {
  border: 1px solid rgb(216, 216, 216);
  padding: 0 0 0 15px;
  font-size: .8rem;
}

.contact-container div textarea {
  height: 150px;
  resize: none;
  margin-top: 1rem;
  padding-top: 10px;
  /* border: 1px solid red; */
}

.contact-container div span {
  font-weight: 600;
}

.contact-container div .phone-span {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.contact-container div .doble-span div {
  padding: 0;
  margin: 0;
}

.contact-container div .doble-span div:last-child {
  /* border: 1px solid red; */
  margin-left: 10px;
  color: rgb(179, 179, 179);
}

.contact-container .checkArea {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-container .checkArea input {
  border: none;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  box-shadow: none;
  cursor: pointer;
}

.contact-container .events {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-container .accordion {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  margin-left: 0px;
  width: 100%;
  transition: height 0.8s;
  overflow: hidden;
  cursor: default;
}

.contact-container .accordion .content-accordion {
  display: flex;
  width: 100%;
}

.contact-container .accordion .content-accordion select{
  height: 40px;
  border: 1px solid #bebebe;
  border-radius: 3px;
  background-color: #fff;
  color: #535353;
  font-weight: 500;
  font-size: 10pt;
  width: 100%;
  padding-top: 10px;
}

.contact-container .accordion .content-accordion .error__select {
  background-color: #fffafa;
  border: 1px solid #dc3545 !important;

  height: 40px;
  border-radius: 3px;
  color: #535353;
  font-weight: 500;
  font-size: 10pt;
}

.contact-container .accordion .content-accordion .area-level-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 0 0;
}

.contact-container .accordion .content-accordion .area-level-selection .container-input-label {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.contact-container
.accordion
.content-accordion
.area-level-selection
.container-input-label
.label-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

.contact-container
.accordion
.content-accordion
select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: normal;
  padding: 10px;
  width: auto;
}

.contact-container
.accordion
.content-accordion
.area-level-selection {
  display: flex;
  flex-direction: column;
}

.contact-container
.accordion
.content-accordion
.area-level-selection
.container-input-label {
  width: 100%;
}

.contact-container
.accordion
.content-accordion
.area-level-selection
.container-input-label
.dropDownSelectLevel {
  /* border: 1px solid red; */
  margin-bottom: 10px;
}

.contact-container
.accordion
.content-accordion
.area-level-selection
.container-input-label
.label-container {
  /* border: 1px solid red; */
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.check-terms {
  margin-bottom: 0px !important;
}

.contact-container .check-terms .terms-input {
  margin-left: 0px;
  padding-left: 0px;
  margin-left: -9px;
}

.hide-display {
  height: 0px;
}

.show-display {
  height: auto;
}

@media only screen and (max-width: 550px) {
  .react-modal-content {
    height: 99.99999%;
    border-radius: 0px;
  }
}

/* CannotPostulateModal Style */

.postulation__modal-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;

  padding: 40px 30px;
}

.postulation__modal-header {
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 2;
  text-transform: uppercase;

  margin-bottom: 25px;
}

.postulation__input_radio {
  margin: 10px 20px 10px 0;
  margin-left: 18px;
  display: -webkit-flex;
  display: flex;
  padding: 0 30px;
  padding-right: 8px;
  margin-right: 23px;
}

.postulation__input {
  margin-right: 20px;
}

.postulation__close_btn {
  height: 40px;
  background-color: var(--primary);
  font-size: 0.85rem;
  text-transform: uppercase;
  border-color: var(--primary);
  border-radius: 3px;
  color: white;
  font-weight: 500;
  width: 100%;
}

.radio_inputs {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 25px;
}

.checkbox__holder {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.span__holder {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
}

.vacantest__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  outline: none;
  -webkit-appearance: none;
}

.contact-container .error {
  color: #dc3545;
  font-size: .9em;
  font-weight: normal;

  margin: 0;
  padding: 0;
}

.vacantest__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-size: 20px;
  font-weight: 900;
}

.vacantest__checkbox:checked {
  background-color: #333;
  border: none;
}

.vacantest__checkbox:checked:after {
  display: block;
}

.vacantest__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

@media all and (max-width: 425px) {
  .postulation__modal-wrapper {
    width: 95%;
  }
}