$containers: ("xs": 350px, "sm": 540px, "md": 780px, "lg": 1024px, "xl": 1440px);
$screen-xs-min: 0px; 
$screen-sm-min: 540px;
$screen-md-min: 780px;
$screen-lg-min: 1024px;
$screen-xl-min: 1440px;

$grid-gutter: 1%;
$grid-max: 980px;
$grid-columns: 12;



@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
   @media (min-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min})  {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min})  {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
       @content;
   }
}



@mixin breakpoint ($container) {
  @if $container == xs {
    @include xs() {
      @content;

    }
  }
  @else if $container == sm {
    @include sm() {
      @content;

    }

  }
  @else if $container == md {
    @include md() {
      @content;
    }

  }
  @else if $container == lg {
    @include lg() {
      @content;

    }

  }
  @else {
    @include xl() {
      @content;

    }

  }
}