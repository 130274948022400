
.experience-benefits-banner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  background-color: inherit;

  .experience-benefits-banner__image__container {
    display: flex;

    .experience-benefits-banner__image {
      width: 60px;
      min-width: 60px;
      height: 60px;
      min-height: 60px;
    }
  }

  .experience-benefits-banner__content__container {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    gap: 12px;

    .experience-benefits-banner__content {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .experience-benefits-banner__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        color: #1A1A1A;
      }
      .experience-benefits-banner__subtitle {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;   
        color: #666666;
      }
    }

    .experience-benefits-banner__button {
      height: 34px;
      width: 120px;
      max-width: 120px;
      background-color: #0096ED26;
      color: #006BA9;
      padding: 8px 18px 8px 18px;
      border-radius: 100px;
      border-width: 0;
      margin-left: auto;

      font-size: 12px;
      font-weight: 600;
      line-height: 18px;

    }
  }
}
