.experience-main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 69px);
  height: calc(100dvh - 69px);

  &.with-footer {
    height: calc(100vh - 130px - 69px);
    height: calc(100dvh - 130px - 69px);
  }

  &-content-container {
    display: flex;
    background-color: #F0F0F0;
    
    &--is-flow {
      background-color: #fff;
    }
  }

  @media (max-width: 700px) {
    .experience-benefits-banner {
      .experience-benefits-banner__content__container {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        .experience-benefits-banner__button {
          margin-left: unset;
        }
      }
    }
  }

  @media (max-width: 596px) {
    .experience-benefits-banner {
      flex-direction: column;
      .experience-benefits-banner__content__container {
        align-items: center;

        & * {
          text-align: center!important;
        }
        
        .experience-benefits-banner__content {
          .experience-benefits-banner__subtitle {
            display: none;
          }
        }
      }
    }
  }
}
