.input-search {
  position: static;
  border-radius: 8px;
  border-style: solid;
  border-color: var(--black-90, rgba(0, 0, 0, 0.9));
  color: var(--black-90, rgba(0, 0, 0, 0.9));
  border-width: 2px;
  padding: 5px 32px 5px 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 52px;
  padding-left: 36px;
  width: 100%;

  &:focus-visible {
    outline: none;
    border-color: var(--black-90, rgba(0, 0, 0, 0.9));
  }

  &__container {
    position: relative;
    display: flex;
    gap: 20px;
  }

  &__search-icon {
    position: absolute;
    top: 16px;
    left: 10px;
  }

  &__delete-icon {
    position: absolute;
    top: 16px;
    right: 14px;
  }
}

