@keyframes experience-loading-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.experience-loading {
  color: #000;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(255,255,255,0.8);

  &--small {
    position: relative;
    background: transparent;
    display: inline-flex;

    .experience-loading__spinner {
      width: 25px;
      height: 25px;
      border-width: 6px;
      border-top-width: 6px;
      animation: experience-loading-spin 0.5s linear infinite;
    }
  }

  &--fullscreen {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0096ed; /* Blue */
    border-radius: 50%;
    width: 106px;
    height: 106px;
    animation: experience-loading-spin 1s linear infinite;
  }

  &__body {
    margin-top: 60px;
  }
}

.left-240 {
  left: 240px;
}