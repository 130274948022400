.school-search {
  box-sizing: border-box;
  position: relative;

  &__list {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    border-radius: 12px;
    left: -4px;
    background-color: #fff;
    max-height: 45vh;
    overflow-y: auto;

    .school-item {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      justify-content: flex-start;
      height: 80px;
      width: 100%;
      position: relative;
      border-radius: 0;
      border-color: #DDDDDD;
      border-style: solid;
      border-width: 0 0 1px 0;
      color: rgba(0, 0, 0, 0.8);
      padding: 20px;

      .school-image {
        width: 40px;
        height: 40px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .school-item__info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        position: relative;

        .school-item__name__text {
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;

          text-align: left;
          position: relative;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .school-item__address__text {
          color: #666666;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;

          text-align: left;
          position: relative;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        
      }

      .school-item__action {
        margin-left: auto;
        
        button {
          background-color: transparent;
          border: none;
          text-decoration: underline;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          text-align: center;

        }
      }
    }

    &__footer {
      color: #666666;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
    }
  }
}
